import { formatISO, format } from 'date-fns';
import axios from 'axios';
import {
  getAppointmentArrivalEnd,
  getDataFromObject,
  getElevatorValue,
  getTrueItems,
  getTypeOfService,
  isGoodsElevatorSelected,
  replaceValue,
  formatPrice,
} from '../../Utils';

const YAML = require('json-to-pretty-yaml');

export function helpChoiceLogic(context) {
  const prefix="shipment-destination";
  let serviceContents = [];
  let customizedQuotes = {
    movingBase: 0,
    pickupHelp: 0,
    packingHourly: 0,
    dIY: 0,
    firstGoodsElevator: 0,
    secondGoodsElevator: 0,
  };

  const selectedPlan = context.selectedData['help'];
  const selectedPlan2 = context.selectedData['help2'];

  const unit = context.selectedData['unit'];
  const plans = context.content?.prices ? context.content.prices.prices.plans.filter(item => item.size === unit) : [];
  const plan = plans && plans.length > 0 ? plans[0] : {};
  // const hourlyPrice = context.content?.prices?.prices?.hourly;

  for (let i = 0; i < context.content.customizeQuote.services.services.length; i++) {
    const service = context.content.customizeQuote.services.services[i];

    if (selectedPlan === service.type) { // selectedPlan
      for ( let idx = 0; idx < service.serviceContents.length; idx ++ ) {
        let serviceContent = service.serviceContents[idx];

        let selected = [];
        let price = [];
        let description = serviceContent.description;
        if (serviceContent.type === 'moving') { // moving
          let movingPrice = plan ? plan.movingPrice.moving : 0;
          let pickupPrice = plan ? plan.movingPrice.helpPickup : 0;
          // New moving price logic
          movingPrice += pickupPrice;

          selected = [
            context.selectedData['needMoving'],
          ];
          price = [
            {
              value: plan ? movingPrice : 0,
              description: serviceContent.price[0].description,
            },
          ];
          customizedQuotes.movingBase = price[0].value;
        } else if (serviceContent.type === 'pickup') { // pickup
          selected = [true];
          price = [
            {
              value: plan ? plan.movingPrice.helpPickup : 0,
              description: serviceContent.price[0].description,
              // description: `+${formatPrice(hourlyPrice, '€')}${serviceContent.price[0].description}`,
            },
          ];
          customizedQuotes.pickupHelp = price[0].value;
          description = serviceContent.description.map(item => {
            return replaceValue(item, [plan.workers]);
          });
        } else if (serviceContent.type === 'packing') { // packing
          selected = [true];

          if ( selectedPlan === 'pickup' ) {
            if (selectedPlan2 !== 'full') {
              selected = [false];
            } else {
              selected = [true];
            }
          }

          price = [{
            value: plan ? plan.movingPrice.packing : 0,
            description: serviceContent.price[0].description,
          }];
          customizedQuotes.packingHourly = price[0].value;
        } else if (serviceContent.type === 'goodsElevator') { // goodsElevator
          selected = [
            false,
            false,
          ];
          price = serviceContent.price;
          customizedQuotes.firstGoodsElevator = price[0].value;
          customizedQuotes.secondGoodsElevator = price[1].value;
        } else { // DIY
          // price = [{
          //   value: plan ? plan.movingPrice.DIY * plan.volume : 0,
          //   // description: serviceContent.price[0].description,
          // }];
          price = serviceContent.price;
          customizedQuotes.dIY = price[0].value;
        }

        serviceContents.push({
          ...serviceContent,
          disabled: serviceContent.type === 'moving',
          icon: context.content.customizeQuote.images[idx].fields.file.url,
          selected,
          price,
          description,
          addressImage: serviceContent.type === 'DIY' && context.content.customizeQuote.images[context.content.customizeQuote.images.length - 1].fields?.file.url,
        })
      }
    }
  }

  context.setSelectedObject({
    customizedQuotes,
    serviceContents,
    needHelpPack: true,
    needHelpPickup: true,
    'goodsElevator': 'goods-elevator-answer-yes',
    'shipment-destinationgoodsElevator': `${prefix}goods-elevator-answer-yes`,
  })
}

export function customizeQuoteLogic(context) {
  const prefix="shipment-destination";
  const selectedPlan = context.selectedData.help;
  context.setSelectedObject({
    movingBasePrice: selectedPlan === 'pickup' && context.selectedData['needMoving']? context.selectedData.customizedQuotes.movingBase : 0,
    pickupHelpPrice: selectedPlan === 'pickup' && context.selectedData['needHelpPickup'] ? context.selectedData.customizedQuotes.pickupHelp : 0,
    packingHourlyPrice: selectedPlan === 'pickup' && context.selectedData['needHelpPack'] ? context.selectedData.customizedQuotes.packingHourly : 0,
    dIYPrice: selectedPlan === 'DIY' ? context.selectedData.customizedQuotes.dIY : 0,
    firstGoodsElevatorPrice: selectedPlan === 'pickup' && isGoodsElevatorSelected(context.selectedData, 'goodsElevator') ? context.selectedData.customizedQuotes.firstGoodsElevator : 0,
    secondGoodsElevatorPrice: selectedPlan === 'pickup' && isGoodsElevatorSelected(context.selectedData, `${prefix}goodsElevator`) ? context.selectedData.customizedQuotes.secondGoodsElevator : 0,
    package: (selectedPlan === 'pickup' && context.selectedData.needHelpPickup) ? 'full' : 'basic',
  });
}

export function calculateDateTime(context) {
  const selectedDatePeriod = context.selectedData.datePeriod;
}

export function book(context, nextStep, prefix) {
  const {
    content,
    step: currentStep,
    focusedStep,
    selectedData,
    canContinue,
    setStep,
    setSelectedData,
    setSelectedObject,
  } = context;
  // const hourlyPrice = context.content?.prices?.prices?.hourly;

  try {
    setSelectedData("bookingInProcess", true);
    const items = YAML.stringify(selectedData.items)
    const coverageValue = content?.prices?.prices?.protectionPlans[selectedData.protection]?.coverageValue || 1;
    const selectedPlan = selectedData.help;
    // const protectionCoverage = `€${coverageValue}/κιλό ανά αντικείμενο`;
    let commitment = 'No Commitment';
    let discountStorage = 0;
    if (selectedData.monthlyStorage === 'm8') {
      commitment = '8';
      // discountStorage = 1;
    } else if (selectedData.monthlyStorage === 'm4') {
      commitment = '4';
      // discountStorage = 0.5;
    } else if (selectedData.monthlyStorage === 'm1') {
      commitment = '1';
    }
    const isTruckFree = (selectedData.unit === '1m x 2m' || selectedData.unit === '2m x 2m')
      && (selectedData.monthlyStorage === 'm4' || selectedData.monthlyStorage === 'm8')
      && (selectedData.package === 'basic' || selectedData.package === 'full');
    
    const plan = content.prices.prices.plans.filter(plan => plan.size == selectedData.unit)[0];
    const calendarId = plan.volume <= 8 ? 4053466 : 4053439;

    const fields = {
      'Unit Size': selectedData.unit,
      'Unit name': plan?.description || '',
      'Volume': plan?.volume || 0,
      'Pickup address': selectedData.address?.address || '',
      "Pickup address url": selectedData.address?.address ? `https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(selectedData.address.address)}` : '',
      'Pickup building': getDataFromObject(selectedData.BuildingChoice, 'string'),
      'Pickup floor': getDataFromObject(selectedData.Floor),
      'Pickup elevator': getDataFromObject(selectedData.elevator, 'string'),
      'Pickup goods elevator': getElevatorValue(selectedData.goodsElevator),
      'Destination1 building': getDataFromObject(selectedData['shipment-destinationBuildingChoice'], 'string'),
      'Destination1 address': selectedData['shipment-destinationaddress']?.address || '',
      'Destination1 address url': selectedData['shipment-destinationaddress']?.address ? `https://www.google.com/maps/search/?api=1&query=${(context.selectedData['shipment-destinationaddress'] && encodeURIComponent(context.selectedData['shipment-destinationaddress'].address)) || ''}` : '',
      'Destination1 floor': getDataFromObject(selectedData['shipment-destinationFloor']),
      'Destination1 elevator': getDataFromObject(selectedData['shipment-destinationElevator'], 'string'),
      'Destination1 goodsElevator': getElevatorValue(selectedData['shipment-destinationgoodsElevator']),
      'Moving scope': selectedData.moveOutOrStoreExtra === 'moving' ? 'Moving Out' : 'Extra Storage',
      'Help packing boxes': selectedData.needHelpPack ? 'Yes' : 'No',
      'Packing Types': getTrueItems(selectedData.packed, content?.whatBeingPacked?.cats?.items?.map(item => item.name) || []),
      'How many items moving': items,
      'Rooms': getTrueItems(selectedData.rooms, content?.whichRooms?.rooms?.items?.map(item => item.name) || []),
      'Type of Service': getTypeOfService(selectedData),
      'Quote': selectedData.finalBill,
      'Protection Plan': content?.prices?.prices?.protectionPlans[selectedData.protection]?.plan || 'Basic',
      'Cost Per Item': content?.prices?.prices?.protectionPlans[selectedData.protection]?.costPerItem || 0,
      'Protection': content?.prices?.prices?.protectionPlans[selectedData.protection]?.price || 0,
      'Protection Coverage': coverageValue,
      // 'Appointment Date Time': formatISO(selectedData.time),
      // 'Appointment Date': format(selectedData.time, 'yyyy-MM-dd'),
      // 'Appointment Arrival Start': format(selectedData.time, 'hh:mmaaa'),
      // 'Appointment Arrival End': getAppointmentArrivalEnd(selectedData.time),
      'Payment Type': '',
      'Marketing origin': getDataFromObject(selectedData.howDidHear, 'string'),
      'Prospect-name': selectedData.contact.name,
      'Prospect-email': selectedData.contact.email,
      'Prospect-tel': selectedData.contact.phone,
      'Storage': selectedData.monthlyStoragePrice,
      'Base Storage Fee': selectedData.monthlyStorageBasePrice,
      'Per M3 Fee': selectedData.monthlyStorageAdditionalPrice,
      'Commitment': commitment,
      'Discount Storage': discountStorage,
      'Moving Base': selectedData.needMoving ? selectedData.movingBasePrice : 0,
      'Discount Truck': isTruckFree ? 1 : 0,
      'Workers': plan?.workers || '1-2',
      'Pickup Help': selectedData.needHelpPickup ? selectedData.pickupHelpPrice : 0,
      'Packing Hourly': selectedData.needHelpPack ? selectedData.packingHourlyPrice : 0,
      // 'Hourly': hourlyPrice,
      'DIY': selectedData.dIYPrice,
      'First Goods Elevator': isGoodsElevatorSelected(selectedData, 'goodsElevator') ? (selectedData.firstGoodsElevatorPrice || 0) : 0,
      'Second Goods Elevator': isGoodsElevatorSelected(selectedData, `${prefix}goodsElevator`) ? (selectedData.secondGoodsElevatorPrice || 0) : 0,
      'Items - Beds': selectedData.items?.beds || 0,
      'Items - Furniture': selectedData.items?.furniture || 0,
      'Items - Sofas': selectedData.items?.sofas || 0,
      'Items - Tables': selectedData.items?.tables || 0,
      'Items - TVs': selectedData.items?.tv || 0,
      'Items - Boxes': selectedData.items?.boxes || 0,
      'Items - Desks': selectedData.items?.desks || 0,
      'Items - Fragile': 'Yes',
      'Items - Heavy': 'Yes',
      'Truck Option': (selectedPlan === 'pickup' && selectedData.needMoving) ? 'Yes' : 'No',
      'Pickup Option': (selectedPlan === 'pickup' && selectedData.needHelpPickup) ? 'Yes' : 'No',
      'Packing Option': (selectedPlan === 'pickup' && selectedData.needHelpPack) ? 'Yes' : 'No',
      'Included Items': plan?.includedItems || 0,
      'DatePeriod': selectedData.datePeriod,
    }

    axios.post('/appointments?calendarID='+calendarId, {
      firstName: selectedData.contact.name,
      lastName: '',
      email: selectedData.contact.email,
      phone: selectedData.contact.phone,
      // datetime: formatISO(selectedData.time),
      datetime: null,
      fields,
    }).then(response => {
      if (response.data.code === 404 && response.data.err === 'no_available_calendar') {
        setSelectedData("bookingInProcess", false);
        alert(content?.misc?.calendarErrorMessage || 'We could not find an available calendar at this time');
        return;
      }
      // window.gtag_report_conversion()
      // context.setSelectedData("bookingInProcess", false);
      localStorage.removeItem('context');
      localStorage.removeItem('step');
      setSelectedObject({
      	bookingInProcess: false,
      	successfullyBooked: true,
      	// time: selectedData.time,
        time: null,
      }, true);
      setStep(nextStep);
    }).catch(error => {
      console.log('error ==>', error.code);
      setSelectedData("bookingInProcess", false);
      alert('Bad request. Please try again.')
    });
  } catch (e) {
    console.log('book e ==>', e)
    setSelectedData("bookingInProcess", false);
    alert('Bad request. Please try again.')
  }
}
