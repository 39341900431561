import React, { useContext } from 'react';
import { Section } from '../../components/Section/Section';
import QuoteCard from '../../components/QuoteCard/QuoteCard';
import AppContext from '../../AppContext'

import './CustomizeQuote.css';


export default function GoodsElevator(props) {
  const context = useContext(AppContext);

  if (!context.content?.customizeQuote?.services?.services.length ||
      !context.content?.helpChoice
    ) {
    return null;
  }

  const serviceContents = context.selectedData.serviceContents;

  if (!serviceContents) {
    return null;
  }

  const goodsElevatorServiceContent = serviceContents.filter(content => content.type === "goodsElevator");

  if (!goodsElevatorServiceContent) {
    return null;
  }

  function onSelect(type, value) {
    if (type === 'DIY') {
      context.setStep(4);
    } else {
      const _serviceContents = [...serviceContents];

      const prefix="shipment-destination";
      _serviceContents[3].selected = value;
      context.setSelectedObject({
        'goodsElevator': `goods-elevator-answer-${value[0] ? 'yes' : 'no'}`,
        'shipment-destinationgoodsElevator': `${prefix}goods-elevator-answer-${value[1] ? 'yes' : 'no'}`,
        serviceContents: _serviceContents,
      });
    }
  }

  return (
    <Section
      className="CustomizeQuote"
      step={props.step}
      title={context.content?.customizeQuote?.header}
    >
      <QuoteCard
        {...goodsElevatorServiceContent[0]}
        onSelect={onSelect}
      />
    </Section>
  )
}
