import React, { useContext, useEffect } from 'react';
import { Section } from '../../components/Section/Section';
import Card from '../../components/Card/Card';
import AppContext from '../../AppContext';

import './Protection.css';

export default function Protection(props) {
  const context = useContext(AppContext);

  const plans = [
    {
      title: context?.content?.protection?.standard,
      name: 'standard',
      selected: context.selectedData['protection'] === 'standard',
      description: context?.content?.prices?.prices?.protectionPlans?.standard.label,
      price: context?.content?.prices?.prices?.protectionPlans?.standard.price,
      label: context?.content?.prices?.prices?.protectionPlans?.standard.mostPopular,
      icon: context?.content?.protection?.images[0].fields.file.url,
    },
    {
      title: context?.content?.protection?.premium,
      name: 'premium',
      selected: context.selectedData['protection'] === 'premium',
      description: context?.content?.prices?.prices?.protectionPlans?.premium.label,
      label: context?.content?.prices?.prices?.protectionPlans?.premium.peaceOfMind,
      price: context?.content?.prices?.prices?.protectionPlans.premium.price,
      icon: context?.content?.protection?.images[1].fields.file.url,
    }
  ];

  function onSelect(name) {
    context.setSelectedData('protection', name);
    if (context.step > props.step)
      return
    context.setStep(props.step + 1);
  }

  useEffect(() => {
    context.setCanContinue(props.step, context.selectedData['protection'] !== void 0)
  }, [props.step, context.selectedData]);

  return (
    <Section
      className="Protection"
      step={props.step}
      title={context.content?.protection?.header}
      description={context?.content?.protection?.subheader}
      hasSkipButton
    >
      {plans.map((plan, i) => {
        return (
          <Card
            protectionCard
            key={`Protection-${i}`}
            {...plan}
            onSelect={onSelect}
            perMonth={context?.content?.misc?.permonth}
          />
        )
      })}
    </Section>
  )
}
