import React, { useContext } from 'react';
import { format } from 'date-fns';
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { Section } from '../../components/Section/Section';
import AppContext from "../../AppContext";
import './style.css'

const AllSet = ({ step }) => {
  const context = useContext(AppContext);
  const selectedTime = context.selectedData['time'] && format(new Date(context.selectedData['time']), 'EEEE, d MMMM');

  return (
    <Section
      step={step}
      title={context?.content?.youAllSet?.header}
      breaklineTitle={context?.content?.youAllSet?.subtitle + ':'}
      breaklineTitle1={selectedTime}
    >
      {context?.content?.youAllSet?.qandA?.items.map((item, i) => (
        <Card
          key={`AllSet-Card-${i}`}
          title={item.q}
          description={item.a}
        />
      ))}
    </Section>
  );
}

const Card = ({ title, description }) => {

  return (
    <div style={{
      alignSelf: 'center',
      boxShadow: 'unset',
      border: '1px solid rgb(9, 126, 119)',
      marginBottom: 16,
      borderRadius: '0 !important',
    }}>
      <Accordion style={{ width: '100%' }}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon style={{ color: 'rgb(9, 126, 119)' }} />}
          aria-controls="panel1a-content"
        >
          <Typography variant="h3" style={{ color: 'black', fontSize: '1.5rem' }} className='accordionTitle'>{title}</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography style={{ color: 'rgb(75, 76, 77)' }}>
            {description}
          </Typography>
        </AccordionDetails>
      </Accordion>
    </div>
  );
}

export default AllSet;
