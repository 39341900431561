import React, { Component } from 'react';
import { format, startOfDay, isEqual, eachDayOfInterval, sub, add, parseISO } from 'date-fns';
import axios from 'axios';
import { Title } from '../Typography';
import { Radio } from '../Radio/Radio';
import LoadingModal from '../../components/LoadingModal';
import { getAppointmentArrivalEnd } from '../../Utils';
import './TimePicker.css';

class TimePicker extends Component {
  ADDITIONAL_DAYS = 1;

  state = {
    avalableTimes: {},
    loaded: false,
  }

  onChange = (e) => {
    this.props.onChange && this.props.onChange(e.target.value);
  }

  getRange() {
    return eachDayOfInterval({
      start: sub(new Date(this.props.date), { days: this.ADDITIONAL_DAYS }),
      end: add(new Date(this.props.date), { days: this.ADDITIONAL_DAYS }),
    });
  }

  loadAvailableTimes() {
    this.setState({
      loaded: false,
      avalableTimes: [],
    });

    const days = this.getRange()
      .filter((date) => !Object.keys(this.state.avalableTimes).includes(date.toString));
    const selectedDate = this.props.date;
		const calendarId = this.props.context.content.prices.prices.plans.filter(plan => plan.size == this.props.context.selectedData.unit)[0].volume <= 8 ? 4053466 : 4053439
    const formattedDays = days.map(item => format(item, 'yyyy-LL-dd'));
    axios.get(`/availability/times?calendarID=${calendarId}`, {
      params: { dates: formattedDays }
    }).then((response) => {
      if (this.props.date !== selectedDate) {
        console.warn('skip', selectedDate);
        return null;
      }

      let result = {};
      for (let i = 0; i < days.length; i++) {
        const day = days[i];
        const formattedDay = format(day, 'yyyy-LL-dd');
        result[day.toString()] = response.data[formattedDay].map(({ time }) => parseISO(time));
      }

      this.setState({
        avalableTimes: {
          ...this.state.avalableTimes,
          ...result,
        },
        loaded: true,
      })
    }).catch(e => {
      this.setState({ loaded: true });
    });
  }

  componentDidMount() {
    this.loadAvailableTimes();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.date !== this.props.date) {
      this.loadAvailableTimes();
    }
  }

  render() {
    const availableTimes = Object.keys(this.state.avalableTimes)
      .map((raw) => new Date(raw))
      .sort((a, b) => +a - +b);

    return (
      <div>
        {availableTimes.map((dayStart, index) => {
          const rawDayStartDate = dayStart.toString();
  
          return (
            <div key={`time-picker-${index}`} className="TimePicker">
              <Title className="TimePicker__day">{format(dayStart, 'EEEE, iii do')}</Title>
              <div className="TimePicker__times">
                {this.state.avalableTimes[rawDayStartDate].map((date, i) => {
                  const inputID = `date-${+date}`;

                  return (
                    <Radio
                      key={`time-picker-inner-${i}`}
                      name="date"
                      checked={isEqual(date, this.props.selected)}
                      id={inputID}
                      value={+date} onChange={this.onChange}
                    >
                      {`${format(date, 'hh:mmaaa')} - ${getAppointmentArrivalEnd(date)}`}
                    </Radio>
                  )
                })}
              </div>
            </div>
          )
        })}
        <LoadingModal
          title={this.props.context?.content?.misc?.loaderTitle}
          open={!this.state.loaded}
          backgroundColor="transparent"
          backdropColor="rgba(255, 255, 255, 0.8)"
        />
      </div>
    );
  }
}

export default TimePicker;
