import React, { useContext } from 'react';
import { Section } from '../../components/Section/Section';
import Card from '../../components/Card/Card';
import AppContext from '../../AppContext';

import './MonthlyStorageChoice.css';

export default function MonthlyStorageChoice(props) {
  const context = useContext(AppContext);
  const selectedPlan = context.selectedData['monthlyStorage'];

  if (!context.content?.monthlyStorage?.services?.services.length) {
    return null;
  }

  const unit = context.selectedData['unit'];
  const unitPlans = context.content?.prices
    ? context.content.prices.prices.plans.filter(item => item.size === unit)
    : [];
  const unitPlan = unitPlans && unitPlans.length > 0 ? unitPlans[0] : {};

  const plans = context.content.monthlyStorage.services.services.map((service, i) => {
    const { base, additional, additionalPlus } = service;
    let price = base;
    let volumePlus = service.volumePlus || 8;

    if ( unitPlan.volume > 4 && unitPlan.volume <= volumePlus ) {
      price += additional * (unitPlan.volume - 4);
    } else if ( unitPlan.volume > volumePlus ) {
      price += additional * (volumePlus - 4);
      price += additionalPlus * (unitPlan.volume - volumePlus);
    }

    return {
      ...service,
      title: service.commitmentTitle,
      description: service.commitmentDescription,
      selected: selectedPlan === service.type,
      name: service.type,
      price,
      icon: context.content.monthlyStorage.images[i].fields.file.url,
      perMonth: `/${service.perUnit}`,
    };
  }) || [];

  function moveToNext() {
    const nextStep = 4;
    context.setStep(nextStep);
  }

  function onSelect(name, index) {
    context.setSelectedObject({
      monthlyStorage: name,
      monthlyStoragePrice: parseFloat(plans[index].price.toFixed(2)),
      monthlyStorageBasePrice: parseFloat(plans[index].base.toFixed(2)),
      monthlyStorageAdditionalPrice: parseFloat(plans[index].additionalPlus.toFixed(2)),
    });
    if (context.step > props.step) {
      return;
    }

    moveToNext();
  }

  return (
    <Section
      canContinue={!!selectedPlan}
      className="MonthlyStorageChoice"
      step={props.step}
      title={context?.content?.monthlyStorage?.header}
      description={context?.content?.monthlyStorage?.subheader}
    >
      {plans.map((plan, i) => {
        return (
          <Card
            protectionCard
            key={`MonthlyStorageChoice-Card-${i}`}
            {...plan}
            onSelect={(name) => onSelect(name, i)}
            perMonth={plan.perMonth}
          />
        )
      })}
    </Section>
  )
}
