import React, { useContext } from "react";
import { Section } from "../../components/Section/Section";
import AppContext from "../../AppContext";

import "./PickDate.css";


export default function PickDatePeriod(props) {
  const context = useContext(AppContext);

  if (!context.content?.dateApprox?.options.length) {
    return null;
  }

  const optionsData = context.content?.dateApprox?.options;
  const selectedDatePeriod = context.selectedData.datePeriod;

  function onSelect(datePeriod) {
    context.setSelectedObject({ datePeriod });
    setTimeout(() => {
      context.setStep(props.step + 1);
    }, 500);
  }

  return (
    <Section
      canContinue={!!context.selectedData['date']}
      step={props.step}
      title={context.content?.dateApprox?.header}
    >
      <ul className="date-options">
        {context.content &&
          optionsData.map((option, index) => {
            return (
              <DateChoice
                key={`DateChoice-${index}`}
                title={option.title}
                value={option.value}
                onSelectDate={onSelect}
                isSelected={selectedDatePeriod === option.title}
              />
            );
          })}
      </ul>
    </Section>
  );
}

const DateChoice = ({ title, value, onSelectDate, isSelected }) => {
  return (
    <li
      className={`date-option ${isSelected && 'date-option--selected'}`}
      onClick={() => onSelectDate(title)}
    >
      <label className="date-option-inner">
        <span className="date-option__title">{title}</span>
      </label>
    </li>
  );
};
