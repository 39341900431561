import React, {useContext} from 'react';
import { ReactComponent as BackIcon } from '../../assets/svgs/back.svg';
import { Button } from '../Button/Button';
import { validateChoice } from '../../Utils';
import {
  helpChoiceLogic,
  customizeQuoteLogic,
  book,
  calculateDateTime,
} from './actions';
import AppContext from '../../AppContext';
import './index.css';

function Footer({ prefix, onSubmitContactForm }) {
  const context = useContext(AppContext);
  const {
    content,
    step: currentStep,
    focusedStep,
    selectedData,
    canContinue,
    setStep,
    setSelectedData,
    setSelectedObject,
  } = context;

  let nextStatus = true;
  let nextButtonTitle = content?.misc?.next;
  let nextActionType = 'next';
  let nextStep = 1;
  let prevStep = 0;

  switch (focusedStep) {
    case 0:
      prevStep = 0;
      nextStep = 1;
      break;
    case 1:
      prevStep = 0;
      nextStep = 2;
      break;
    case 2:
      prevStep = 1;
      nextStep = 3;
      nextStatus = !!selectedData.unit;
      break;
    case 3:
      prevStep = 2;
      nextStep = 4;
      nextStatus = !!selectedData.monthlyStorage;
      break;
    case 4: // HelpChoice
      prevStep = 3;
      if (selectedData.help === 'DIY') {
        nextStep = 6;
      } else {
        nextStep = 5;
      }
      // nextStep = 5;
      nextStatus = !!selectedData.help;
      break;
    case 5: // HelpChoice2
      prevStep = 4;
      nextStep = 6;
      nextStatus = !!selectedData.help2;
      break;
    case 6:
      prevStep = 5;
      if (selectedData.help === 'DIY') {
        nextStep = 18; // ContactInfo
      } else {
        nextStep = 7;
      }
      nextStatus = !!selectedData.help; // !!selectedData.package;
      break;
    case 7:
      prevStep = 6;
      nextStep = 8;
      nextStatus = !!selectedData.help;
      break;
    case 8:
      prevStep = 7;
      nextStep = 9;
      nextStatus = !!selectedData.address?.address;
      break;
    case 9:
      prevStep = 8;
      nextStep = 10;
      nextStatus = validateChoice(selectedData.BuildingChoice);
      break;
    case 10:
      prevStep = 9;
      nextStep = 11;
      nextStatus = validateChoice(selectedData.Floor);
      break;
    case 11:
      prevStep = 10;
      nextStep = 12;
      nextStatus = validateChoice(selectedData.elevator);
      break;
    case 12: // ShipmentDestination
      prevStep = 11;
      if (selectedData['shipment-destination'] === 'warehouse') {
        nextStep = 18; // Contact Info
      } else {
        nextStep = 13; // ShipmentDestinationAddress
      }
      nextStatus = !!selectedData['shipment-destination'];
      break;
    case 13:
      prevStep = 12;
      nextStep = 14;
      nextStatus = !!selectedData[`${prefix}address`]?.address;
      break;
    case 14:
      prevStep = 13;
      nextStep = 15;
      nextStatus = validateChoice(selectedData[`${prefix}BuildingChoice`]);
      break;
    case 15:
      prevStep = 14;
      nextStep = 16;
      nextStatus = validateChoice(selectedData[`${prefix}Floor`]);
      break;
    case 16:
      prevStep = 15;
      nextStep = 17;
      nextStatus = !!selectedData.moveOutOrStoreExtra;
      break;
    case 17:
      prevStep = 16;
      nextStep = 18;
      nextStatus = true;
      break;
    case 18:
      prevStep = 17;
      nextStep = 19;
      nextActionType = 'contact';
      nextStatus = !!(canContinue && canContinue['18']); // ContactInfo
      break;
    case 19:
      prevStep = 18;
      nextStep = 20;
      nextStatus = !!selectedData.datePeriod;
      break;
    case 20:
      prevStep = 19;
      nextStep = 21;
      nextStatus = validateChoice(selectedData.howDidHear);
      break;
    case 21:
      prevStep = 20;
      nextStatus = true;
      nextButtonTitle = context?.content?.misc?.bookAppt;
      if (selectedData.help === 'DIY') {
        nextActionType = 'book';
        nextStep = 26;
      } else {
        nextStep = 22;
      }
      break;
    case 22:
      prevStep = 21;
      nextStep = 23;
      nextStatus = true;
      break;
    case 23:
      prevStep = 22;
      nextStep = 24;
      nextStatus = validateChoice(selectedData.packed);
      break;
    case 24:
      prevStep = 23;
      nextStep = 25;
      nextStatus = validateChoice(selectedData.rooms);
      break;
    case 25: // WhichItems
      prevStep = 24;
      if (selectedData.package === 'basic') {
        nextStep = 17; // Protection
      } else {
        nextStep = 26; // AddServices(Addon)
      }
      nextActionType = 'book';
      nextStatus = validateChoice(selectedData.items);
      break;
    // case 23:
    //   prevStep = 22;
    //   nextStep = 24;
    //   nextStatus = !!selectedData.time;
    //   break;
    // case 24:
    //   prevStep = 23;
    //   nextStep = 25;
    //   nextStatus = validateChoice(selectedData.howDidHear);
    //   break;
    // case 25:
    //   prevStep = 24;
    //   nextStep = 26;
    //   nextStatus = true;
    //   nextButtonTitle = context?.content?.misc?.bookAppt;
    //   nextActionType = 'book';
    //   break;
    default:
      nextStatus = true;
  }

  function handlePrev() {
    setStep(prevStep);
  }

  function handleNext() {
    if (nextActionType === 'next') {
      if (focusedStep === 4) {// helpchoice
        helpChoiceLogic(context);
      } else if (focusedStep === 5) {// helpchoice2
        helpChoiceLogic(context);
      } else if (focusedStep === 6) { // CustomizeQuote
        customizeQuoteLogic(context);
      } else if (focusedStep === 10) { // Floor
        // setSelectedData('goodsElevator', 'goods-elevator-answer-yes');
      } else if (focusedStep === 15) { // Destination Floor
        // setSelectedData(`${prefix}goodsElevator`, `${prefix}goods-elevator-answer-yes`);
      } else if (focusedStep === 19) { // Date Period
        calculateDateTime(context);
      }

      setStep(nextStep);
    } else if (nextActionType === 'contact') {
      onSubmitContactForm();
    } else if (nextActionType === 'book') {
      book(context, nextStep, prefix);
      // google tag event tracking
      window.gtag_report_conversion();
    }
  }

  return (
    <div className="footer">
      <div className="footer__container">
        <Button
          className="footer--left"
          onClick={handlePrev}
        >
          <BackIcon />
        </Button>
        <Button
          className="footer--right"
          disabled={!nextStatus || selectedData.bookingInProcess}
          onClick={handleNext}
        >
          {nextButtonTitle}
        </Button>
      </div>
    </div>
  )
}

export default Footer;
