import React, { useState, useEffect, useContext } from "react";
import AppContext from "../../AppContext";

import Card from "./Card";
import { Section } from "../../components/Section/Section";

const WhatMakesDiff = ({
  content,
  balance,
  setStep,
  setBalance,
  onVisiblityChange,
  focused,
  step,
}) => {
  const context = useContext(AppContext);
  return (
    <Section
      step={step}
      title={
        context.content &&
        context.content.whatMakesDiff &&
        context.content.whatMakesDiff.header
      }
    >
      <div className="subContainer">
        {context.content &&
          context.content.whatMakesDiff &&
          context.content.whatMakesDiff.cards &&
          context.content.whatMakesDiff.cards.cards.map((card, i) => {
            return (
              <Card header={card.header} subtitle={card.details} key={i} img={context.content.whatMakesDiff.images[i].fields.file.url} />
            );
          })}
      </div>
    </Section>
  );
};

export default WhatMakesDiff;
