import React, { useContext, useRef, useState } from "react";
import Slider from "react-slick";
import { Section } from "../../components/Section/Section";
import { Button } from '../../components/Button/Button';
import UnitModal from '../../components/UnitModal';
import { NextArrow, PrevArrow } from '../../components/Arrow';
import AppContext from "../../AppContext";
import "./UnitSize.css";
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";

const planImages = [
  '5-5-small-closet.jpg',
  '5-10-walkin-closet.jpg',
  '10-10-garage.jpg',
  '10-15-studio.jpg',
  '10-20-1-bedroom-house.jpg',
  '10-25-two-bed.jpg',
  '10-30-3bed.jpg',
];

function UnitSize(props) {
  const context = useContext(AppContext);
  const { step } = props;
  const selectedUnit = context.selectedData["unit"];
  const [modalData, setModalData] = useState(false);
  const [slideIndex, setSlideIndex] = useState(0);
  const [updateCount, setUpdateCount] = useState(0);
  const [hoverIndex, setHoverIndex] = useState(-1);
  const slider = useRef(null);
  let plans = context.content?.prices?.prices?.plans || [];
  const length = plans.length;
  const nextStep = 3;

  const m8Services = context.content?.monthlyStorage
    ? context.content.monthlyStorage.services.services.filter(service => service.type === "m8")
    : [];
  const m8Service = m8Services && m8Services.length > 0 ? m8Services[0] : {};

  const pricedPlans = plans.map((plan, i) => {
    let price = m8Service.base;
    if (plan.volume > 4) {
      price += m8Service.additional * (plan.volume - 4);
    }

    return {
      ...plan,
      price,
    };
  }) || [];

  const selectButtonTitle = context?.content?.misc?.select;
  const perMonth = context?.content?.misc?.permonth;

  let explanationHtml = '';
  if (context.content?.selectUnit?.estimateExplanation?.content) {
    let explanationContents = context.content?.selectUnit?.estimateExplanation?.content[0].content;
    explanationContents.forEach((ec) => {
      if (!ec.marks.length) {
        explanationHtml += ec.value;
      } else if (ec.marks[0].type === 'bold') {
        explanationHtml += '<b>' + ec.value + '</b>';
      }
    });
  }

  const settings = {
    dots: false,
    infinite: true,
    centerMode: true,
    centerPadding: '200px',
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    focusOnSelect: true,
    swipe: false,
    responsive: [
      {
        breakpoint: 1240,
        settings: {
          slidesToShow: 3,
          centerPadding: '30px',
        },
      },
      {
        breakpoint: 840,
        settings: {
          slidesToShow: 1,
          swipe: true,
          centerPadding: '150px',
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          swipe: true,
          centerPadding: '20px',
        }
      }
    ],
    prevArrow: <PrevArrow disabled={slideIndex === 0} hide={modalData}/>,
    nextArrow: <NextArrow disabled={slideIndex === length - 1} hide={modalData}/>,
    afterChange: () => setUpdateCount(updateCount + 1),
    beforeChange: (_, next) => setSlideIndex(next),
  };

  function onSelect(name, unit, index) {
    slider.current.slickGoTo(index, false);
    if (unit.name === "notsure") {
      window.open('https://inventory.spacehero.gr','_blank');
      return;
    }

    if (name === selectedUnit) {
      context.setSelectedData(`unit`, null);
    } else {
      context.setSelectedData(`unit`, name);
    }
    context.setSubstepsCount(step, 1);
    // context.setStep(step);
    context.setStep(nextStep);
  }

  return (
    <Section
      canContinue={selectedUnit !== undefined}
      step={step}
      name="unitsize"
      title={context.content?.selectUnit?.header}
      description={context.content?.selectUnit?.subheader}
      fullChildren
    >
      <div className="UnitSize__units">
        <Slider ref={slider} {...settings} className="Unit__slider">
          {pricedPlans.map((unit, i) => {
            const selected = unit.size === selectedUnit;

            return (
              <div
                key={`unit-${i}`}
                onMouseEnter={() => setHoverIndex(i)}
                onMouseLeave={() => setHoverIndex(-1)}
              >
                <div className="Unit__card">
                  <img
                    className="Unit__image"
                    src={require(`../../assets/images/${planImages[i]}`)}
                    alt={unit.size}
                  />
                  <div className="Unit__upper-desc">{unit.description}</div>
                  <div
                    onClick={() => onSelect(unit.size, unit, i)}
                    className={`Unit${selected ? " Unit--selected" : ""}`}
                  >
                    <div className="Unit__wrap">
                      <label className="Unit__inner">
                        <span className="Unit__title">
                          {
                            // (hoverIndex === i && hoverIndex !== plans.length - 1)
                            <span>{unit.volume ? <span>{unit.volume}m<sup>3</sup></span> : ''}</span>
                          }
                        </span>
                        <span className="Unit__description">Από &euro;{unit.price ? unit.price.toFixed(1) : 0}{perMonth}</span>
                      </label>
                    </div>
                    <button
                        onClick={() => onSelect(unit.size, unit, i)}
                        className="Unit__select"
                      >
                        {selectButtonTitle}
                    </button>
                  </div>

                  <Button
                    size="small"
                    className="Unit__modal-button"
                    onClick={() => setModalData(unit.details)}
                    theme="clear"
                  >
                    {context.content?.prices?.prices?.unitModalButtonLabel}
                  </Button>

                </div>
              </div>
            );
          })}
        </Slider>

        {/* 
          Todo: We made this rate disabled.
          When the range is changed quickly, the carousel goes to the out of sight.
          This is the slickGoTo() menthod issue in the infinite/center mode.
          We should fix this issue.
        */}
        {/* {length > 0 && (
          <Range
            onChange={e => slider.current.slickGoTo(e.target.value, false)}
            value={slideIndex}
            min={0}
            max={length - 1}
            minLabel={plans[0].size}
            maxLabel={plans[length - 1].size}
          />
        )} */}

        <div className="help-block">
          <div className="help-block__note">
            <img src={require('../../assets/svgs/piggy-bank.svg')} alt="block icon" />
            <div dangerouslySetInnerHTML={{ __html: explanationHtml }} />
          </div>

          <div className="help-block__link">
            <a href="https://inventory.spacehero.gr" target="_blank">{context.content?.selectUnit?.estimateButton}</a>
          </div>
        </div>

        <UnitModal
          open={modalData}
          onClose={() => setModalData(null)}
        />
      </div>
    </Section>
  );
}

export default UnitSize;
