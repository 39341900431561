import React, { useState, useContext } from 'react'

import { Section } from '../../components/Section/Section';
import { Radio } from '../../components/Radio/Radio'
import AppContext from "../../AppContext";

const ShipmentDestination = ({ step }) => {
    const context = useContext(AppContext);
    const [moving, setMoving] = useState(false)
    const [notMoving, setNotMoving] = useState(false)

    function moveToNext(nextStep) {
        context.setStep(nextStep);
    }

    return (
        <Section
            canContinue={!!context.selectedData['shipment-destination']}
            step={step} title={
                context.content && context.content.shipmentDestination && context.content.shipmentDestination.header
            }>
            <div onClick={() => {
                setMoving(true)
                setNotMoving(false)
                context.setSelectedData('shipment-destination', 'warehouse')
                context.setSubstepsCount(step, 0);
                moveToNext(18);
            }}
            >
                <Radio id='spWarehouse'
                    checked={moving || context.selectedData['shipment-destination'] == 'warehouse'}
                    onChange={() => {
                    }}
                >
                    {
                        context.content && context.content.shipmentDestination && context.content.shipmentDestination.destinations.items[0]
                    }
                </Radio>
            </div>
            <div
                onClick={() => {
                    setNotMoving(true)
                    setMoving(false)
                    context.setSelectedData('shipment-destination', 'new')
                    context.setSubstepsCount(step, 4);
                    moveToNext(13);
                }}
            >
                <Radio
                    id='someToSpWarehouseSomeToNew'
                    checked={notMoving || context.selectedData['shipment-destination'] == 'new'}>
                    {
                        context.content && context.content.shipmentDestination && context.content.shipmentDestination.destinations.items[1]
                    }
                </Radio>
            </div>
        </Section>

    )
}

export default ShipmentDestination;
