import { format } from 'date-fns';

export function capitalize(str) {
  if (str) {
    return str.charAt(0).toUpperCase() + str.slice(1);
  }

  return '';
}

export function throttle(callback, limit) {
  let waiting = false;
  return function () {
    if (!waiting) {
      callback.apply(this, arguments);
      waiting = true;
      setTimeout(() => {
        waiting = false;
      }, limit);
    }
  };
}

export function formatPrice(price, prefix = '', suffix = '', fixed = 2) {
  if (typeof price === 'string') {
    return price;
  }
  let fixedPrice = 0;
  if (price) {
    fixedPrice = price.toFixed(fixed);
    if (parseInt(fixedPrice, 10) === price) {
      fixedPrice = price.toFixed(0);
    }
  }
  return `${prefix}${fixedPrice}${suffix}`;
}

export function range(start, end) {
  return Array(end - start + 1).fill().map((_, idx) => start + idx)
}

export function getAppointmentArrivalEnd(time) {
  if (!time) {
    return '';
  }

  const endTime = new Date(time);
  endTime.setHours(endTime.getHours() + 3);

  return format(endTime, 'hh:mmaaa');
}


export function getDataFromObject(value, returnType = 'array') {
  let result = [];
  const keys = Object.keys(value || {});
  for (let i = 0; i < keys.length; i++) {
    const item = value[keys[i]];
    if (item) {
      result.push(keys[i]);
    }
  }

  if (returnType === 'array') {
    return result;
  }

  if (result.length > 0) {
    return result[0];
  }

  return '';
}

export function getElevatorValue(value) {
  let result = '';
  if (value) {
    const stringArray = value.split('-');
    if (stringArray) {
      result = capitalize(stringArray[stringArray.length - 1]);
    } else {
      result = 'No';
    }
  } else {
    result = 'No';
  }

  return result;
}

export function getTrueItems(obj, contentKeys) {
  let result = [];
  if (obj) {
    const keys = Object.keys(obj);
    for (let i = 0; i < keys.length; i++) {
      if (obj[keys[i]]) {
        const key = keys[i];
        if (contentKeys.includes(key)) {
          result.push(capitalize(key));
        }
      }
    }
  }

  return result;
}

export function getTypeOfService(selectedData) {
  let result = 'Basic';
  if (selectedData.help === 'pickup') {
    if (selectedData.needHelpPickup) {
      result = 'Full Service';
    }
  } else {
    result = 'DIY';
  }

  return result;
}

export function isGoodsElevatorSelected(selectedData, key) {
  const goodsElevator = selectedData[key] || '';
  const goodsElevatorArray = goodsElevator.split("-");
  const goodsElevatorAnswer = goodsElevatorArray[goodsElevatorArray.length - 1];

  return goodsElevatorAnswer === 'yes' || goodsElevatorAnswer === 'maybe';
}

export function validateChoice(data) {
  if (data) {
    const keys = Object.keys(data);
    for (let i = 0; i < keys.length; i++) {
      if (data[keys[i]]) {
        return true;
      }
    }
  }

  return false;
}

export const replaceValue = (str, values) => {
  if (!str) {
    return '';
  }

  if (!(str.includes('%s'))) {
    return str;
  }

  const strArray = str.split('%s');
  let result = '';
  for (let i = 0; i < strArray.length; i++) {
  	result = result + strArray[i] + (values[i] || '');
  }

  return result;
}