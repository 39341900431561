import React from 'react';

import './Button.css';

export function Button(props) {
  const theme = props.theme || 'primary';
  const size = props.size || 'regular';
  const stretch = props.stretch;

  return (
    <button
      type={props.type}
      className={`
        Button
        Button--${theme}
        Button--${size}
        ${stretch ? ' Button--stretch' : ''}
        ${props.className || ''}
      `}
      onClick={props.onClick}
      disabled={props.disabled}
    >
      {props.children}
    </button>
  );
}
