import React, { useState, useEffect } from 'react';
import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Loader from '../../components/Loader/Loader';

const useStyles = makeStyles(theme => ({
  modal: {
    position: 'fixed',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    zIndex: 9999,
  },

  paper: {
    width: 323,
    outline: 'none',
    padding: '24px 46px 80px',
    borderRadius: 4,
  },
  
  logo: {
    width: '100%',
    textAlign: 'center',
    marginBottom: 50,

    '& img': {
      width: 120,
      height: 23,
      objectFit: 'contain',
    },
  },
  
  title: {
    fontSize: '2rem',
    fontWeight: 900,
    fontFamily: 'Averta-Extrabold, Source Sans Pro, sans-serif',
  },

  description: {
    marginTop: 12,
    marginBottom: 75,
    fontSize: '.875rem',
    color: '#454545',
    fontFamily: 'Averta-Bold, Source Sans Pro, sans-serif',
  },
}));

function LoadingModal(props) {
  const {
    open,
    title,
    description,
    icon,
    backgroundColor,
    backdropColor,
  } = props;
  const classes = useStyles();
  const [active, setActive] = useState();

  useEffect(() => {

    if (open) {
      document.body.style.overflowY = 'hidden';
    } else {
      document.body.style.overflowY = 'auto';
    }
    setActive(open);
  }, [open]);

  if (!active) {
    return null;
  }

  return (
    <div
      className={classes.modal}
      style={{ backgroundColor: backdropColor || 'rgba(0, 0, 0, 0.3)' }}
      onScroll={event => event.stopPropagation()}
    >
      <div
        className={classes.paper}
        style={{ backgroundColor: backgroundColor || 'white' }}
      >
        {icon && (
          <div className={classes.logo}>
            <img src={icon} />
          </div>
        )}
        {title && (
          <Typography className={classes.title} align="center">
            {title}
          </Typography>
        )}
        {description && (
          <Typography className={classes.description} align="center">
            {description}
          </Typography>
        )}
        <Loader />
      </div>
    </div>
   
  );
}

export default LoadingModal;
