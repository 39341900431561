import React from 'react';
import { ReactComponent as ArrowLeft } from '../../assets/svgs/arrow-left.svg';
import './index.css';

function NextPrev(props) {
  const { className, disabled, hide, onClick } = props;

  return (
    <div
      className={`
        slide-arrow
        slide-arrow--prev
        ${className}
        ${disabled && 'slide-arrow--disabled'}
        ${hide && 'slide-arrow--hide'}
      `}
      onClick={() => !disabled && onClick()}
    >
      <ArrowLeft />
    </div>
  );
}

export default NextPrev;