import React, { useState, useContext, useEffect } from "react";
import { Section } from "../../components/Section/Section";
import AppContext from "../../AppContext";

import "./Building.css";

const Building = ({ step, prefix = '' }) => {
  const context = useContext(AppContext);
  const buildingChoiceData = context.selectedData[`${prefix}BuildingChoice`] || {};
  const stepData = prefix ? context.content?.destinationBuildingType : context.content?.buildingType;
  const optionsData = [];
  if (stepData?.buildingtypes?.items) {
    for (let i = 0; i < stepData.buildingtypes.items.length; i++) {
      const item = stepData.buildingtypes.items[i];
      optionsData.push({
        title: item.title,
        value: !!buildingChoiceData[item.title],
      });
    }
  }

  useEffect(() => {
    context.setCanContinue(step, false);
  }, []);

  return (
    <Section
      canContinue={Object.values(buildingChoiceData).length && Object.values(buildingChoiceData).some(v => v)}
      step={step}
      title={stepData?.header}
    >
      <ul className="buildings">
        {context.content &&
          optionsData.map((option, index) => {
            return (
              <BuildingChoice
                key={`BuildingChoice-${index}`}
                title={option.title}
                value={option.value}
                prefix={prefix}
              />
            );
          })}
      </ul>
    </Section>
  );
};

const BuildingChoice = ({ title, prefix, value }) => {
  const context = useContext(AppContext);
  const buildingChoiceData = context.selectedData[`${prefix}BuildingChoice`] || {};

  function moveToNext() {
    const nextStep = !prefix ? 10 : 15;
    context.setStep(nextStep);
  }

  function handleItemClick() {
    const _buildingChoiceData = {...buildingChoiceData};
    if (!value) { // unselect the selected items for signle selection
      const keys = Object.keys(_buildingChoiceData);
      for (let i = 0; i < keys.length; i++) {
        _buildingChoiceData[keys[i]] = false;
      }
    }

    context.setSelectedData(`${prefix}BuildingChoice`, {
      ..._buildingChoiceData,
      [title]: !value,
    });

    moveToNext();
  }

  return (
    <li
      className={`building ${value && 'building--selected'}`}
      onClick={handleItemClick}
    >
      <label className="building-inner">
        <span className="building__title">{title}</span>
      </label>
    </li>
  );
};

export default Building;
