import React, { useContext, useState } from 'react';
import { Section } from '../../components/Section/Section';
import Card from '../../components/Card/Card';
import AppContext from '../../AppContext';
import MapModal from '../../components/MapModal';

import './HelpChoice.css';

export const helpNames = {
  'curbside': 'Curbside pickup',
  'full': 'Full service',
}

export default function HelpChoice(props) {
  const context = useContext(AppContext);
  const [modalVisible, setModalVisible] = useState(false);

  if (!context.content?.customizeQuote?.services?.services.length ||
    !context.content?.helpChoice?.services?.services.length
  ) {
    return null;
  }

  const selectedPlan = context.selectedData.help;
  let storageLocation = null;
  const services = context.content.helpChoice.services.services.map((service, i) => {
    if (service.storageLocation) {
      storageLocation = service.storageLocation;
    }

    return {
      title: service.title,
      description: service.description,
      selected: selectedPlan === service.type,
      name: service.type,
      effect: service.effect,
      icon: context.content.helpChoice.images[i].fields.file.url,
      hasContent: !!storageLocation,
      storageLocation,
    };
  }) || [];

  const helpChoiceImages = context.content.helpChoice.images;
  let storeImage = helpChoiceImages[helpChoiceImages.length - 1].fields.file.url;

  function onSelect(name) {
    context.setSelectedObject({
      help: name,
      needMoving: true,
    });
    if (context.step > props.step)
      return;
  }

  return (
    <>
      <Section
        canContinue={!!selectedPlan}
        className="HelpChoice"
        step={props.step}
        title={context && context.content && context.content.helpChoice && context.content.helpChoice.header}
      >
        {services.map((service, i) => {
          return (
            <Card
              key={`HelpChoice-Card-${i}`}
              {...service}
              size={service.size}
              largeVertical={true}
              onSelect={onSelect}
              onContentButtonClick={() => setModalVisible(true)}
            />
          )
        })}
      </Section>
      {storageLocation && storeImage && (
        <MapModal
          open={modalVisible}
          onClose={() => setModalVisible(false)}
          {...storageLocation}
          storeImage={storeImage}
        />
      )}
    </>
  )
}
