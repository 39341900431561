import React, { useContext, useState, useEffect } from 'react';

import './QuoteCard.css';
import { formatPrice } from '../../Utils';
import Map from "../../components/Map/Map";
import AppContext from '../../AppContext';

import { ReactComponent as CheckMarkImage } from './checkmark.svg'
import { ReactComponent as PlusImage } from './plus.svg';


function QuoteCard(props) {
    const context = useContext(AppContext);
    const confirmAppt = context.content?.confirmAppt;

    const {
        selected,
        disabled,
        onSelect,
        type,
        title,
        description,
        icon,
        address,
        addressImage,
        price,
    } = props;

    function handleOptionClick(e, index) {
        if (type === 'DIY') {
            onSelect(type);
        } else {
            if (type === 'moving' || type === 'goodsElevator') {
                e.preventDefault();
                e.stopPropagation();
                const newSelected = [...selected];
                newSelected[index] = !newSelected[index];
                onSelect(type, newSelected);
            }
        }
    }

    function handleCardClick() {
        if (type !== 'DIY') { // pickup
            const isSelectedAtLeast = selected[0] || selected[1] || false;
            const newSelected = selected.map(item => !isSelectedAtLeast);
            onSelect(type, newSelected);
        }
    }

    const isSelectedAtLeast = selected[0] || selected[1];
    const isSelectedFull = (selected.length === 2) ? (selected[0] && selected[1]) : selected[0];

    return (
        <div
            style={{display: 'flex', flexDirection:'row'}}
            className={`QuoteCard ${disabled && 'QuoteCard--disabled'}`}
            onClick={type !== 'goodsElevator' && !disabled && handleCardClick}
        >
            <div
                style={{display: 'flex', flex: 1, flexDirection:'column'}}
                className={`
                    QuoteCard__inner
                    ${isSelectedFull && !disabled && 'QuoteCard__inner--selected'}
                `}
            >
                <div className="QuoteCard__main">
                    {props.icon && <div className="QuoteCard__left">
                        <img className="QuoteCard__icon" src={icon} />
                    </div>}
                    <div className="QuoteCard__content">
                        <div className="QuoteCard__title">{title}</div>
                        <div className="QuoteCard__description-content">
                        {
                            description.map((item, index) => (
                                <div
                                    key={`QuoteCard__description-${index}`}
                                    className="QuoteCard__description-inner"
                                >
                                    {description.length > 1 && <div className="QuoteCard__description-dot"/>}
                                    <div className="QuoteCard__description">{item}</div>
                                </div>
                            ))
                        }
                        </div>
                    </div>
                </div>
                {address && <Map address={address} addressImage={addressImage} />}
                {price.map((item, index) => (
                    <div key={`QuoteCard__footer-${index}`} className="QuoteCard__footer">
                        <div className="QuoteCard__price">
                            {item.value && (
                                <div className="QuoteCard__price-label">
                                    {formatPrice(
                                        item.value,
                                        '€',
                                        '', // type === 'pickup' ? `/${confirmAppt.moving.perHour}` : '',
                                        type === 'pickup' ? 0 : 2,
                                    )}
                                </div>
                            )}
                            {item.description && <div className="QuoteCard__price-description">{item.description}</div>}
                        </div>
                        {type === 'DIY' && (
                            <div
                                className="QuoteCard__footer-button"
                                onClick={(e) => handleOptionClick(e, index)}
                            >
                                Edit
                            </div>
                        )}
                        {type !== 'DIY' && !disabled && (
                            <div
                                className="QuoteCard__footer-button"
                                onClick={(e) => handleOptionClick(e, index)}
                            >
                                {selected[index] ? 'Remove' : (type === 'goodsElevator' ? 'Add' : '')}
                            </div>
                        )}
                    </div>
                ))}

            </div>
            {!disabled && (
                <div
                    className={`QuoteCard__status
                    ${(isSelectedAtLeast || type === 'DIY') && 'QuoteCard__status--selected'}`}
                    onClick={type === 'goodsElevator' && handleCardClick}
                >
                    {(isSelectedAtLeast || type === 'DIY') ? <CheckMarkImage /> : <PlusImage />}
                </div>
            )}
        </div>
    )
}

export default QuoteCard;
