import React, { useContext } from 'react';
import { format } from 'date-fns';
import { makeStyles } from '@material-ui/core/styles'
import { Section } from '../../components/Section/Section';
import AppContext from '../../AppContext'
import { formatPrice } from '../../Utils';
import LoadingModal from '../../components/LoadingModal';
import DetailsServiceItem from '../../components/DetailsServiceItem';
import { getAppointmentArrivalEnd } from '../../Utils';
import './style.css'

const useStyles = makeStyles(theme => ({
  formContainer: {
    alignSelf: 'center',
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    margin: '0 auto 100px auto',
  },
}));

let ConfirmDetails = (props) => {
  const classes = useStyles();
  const context = useContext(AppContext);
  const confirmAppt = context.content?.confirmAppt;
  let unit = context.selectedData.unit;
  // const prices = context.content?.prices?.prices;

  // const selectedPlan = context.content?.prices?.prices?.plans.find(item => item.size === unit);

  let unitPrice;
  let unitVolume;
  let unitDescripton;
  let workers;
  let monthlyStoragePrice = context.selectedData.monthlyStoragePrice;
  let monthlyStorage = 1;
  let protectionPrice;

  if (!confirmAppt) {
    return null;
  }

  function getTruckItem(isFree) {
    let result;
    if (isFree) {
      result = {
        title: confirmAppt.moving.truckProfessionalLabel,
        value: confirmAppt.moving.freeLabel,
        valueType: 'green',
        value1: `${formatPrice(context.selectedData.movingBasePrice, '€')}`,
        value1Type: 'line',
      }
    } else {
      result = {
        title: confirmAppt.moving.truckProfessionalLabel,
        value: `${formatPrice(context.selectedData.movingBasePrice, '€')}`,
      }
    }

    return result;
  }

  function getMovingData() {
    let editStep = 5;
    let data = [];
    let sumData = [];

    if (context.selectedData.help === 'pickup') {
      const isFree = (context.selectedData.unit === '1m x 2m' || context.selectedData.unit === '2m x 2m')
        && (context.selectedData.monthlyStorage === 'm4' || context.selectedData.monthlyStorage === 'm8')
        && (context.selectedData.package === 'basic' || context.selectedData.package === 'full');

      if (context.selectedData.package === 'full') { // Full Service
        data = [
          {
            type: 'header',
            title: confirmAppt.moving.fullServiceLabel,
          },
          getTruckItem(isFree),
          {
            // title: confirmAppt.moving.movingHelpersLabel,
            title: `${confirmAppt.moving.workersLabel} (${workers} ${confirmAppt.moving.assistantsLabel})`,
            value:  `${formatPrice(context.selectedData.needHelpPickup && (context.selectedData.pickupHelpPrice || 0), '€')}`, //${confirmAppt.moving.perHour}
          },
          {
            title: confirmAppt.moving.packingLabel,
            value: `${formatPrice(context.selectedData.needHelpPack && (context.selectedData.packingHourlyPrice || 0), '€')}`,
          },

        ];

        if (context.selectedData.goodsElevator?.includes('yes')) {
          data.push({
            title: confirmAppt.moving.goodsElevatorLabel,
            value: `${formatPrice(context.selectedData.firstGoodsElevatorPrice, '€')}`,
          });
        }

        if (context.selectedData['shipment-destinationgoodsElevator']?.includes('yes')) {
          data.push({
            title: confirmAppt.moving.secondGoodsElevatorLabel,
            value: `${formatPrice(context.selectedData.secondGoodsElevatorPrice, '€')}`,
          });
        }

        sumData = [
          {
            type: 'header',
            title: confirmAppt.moving.title,
            value: `${formatPrice(
              (isFree ? 0 : context.selectedData.movingBasePrice) +
              context.selectedData.pickupHelpPrice +
              context.selectedData.packingHourlyPrice +
              (context.selectedData.goodsElevator?.includes('yes') ? context.selectedData.firstGoodsElevatorPrice : 0) +
              (context.selectedData['shipment-destinationgoodsElevator']?.includes('yes') ? context.selectedData.secondGoodsElevatorPrice : 0)
              , '€')}`,
            value1: isFree
              ? `${formatPrice(
                context.selectedData.movingBasePrice +
                context.selectedData.pickupHelpPrice +
                context.selectedData.packingHourlyPrice +
                (context.selectedData.goodsElevator?.includes('yes') ? context.selectedData.firstGoodsElevatorPrice : 0) +
                (context.selectedData['shipment-destinationgoodsElevator']?.includes('yes') ? context.selectedData.secondGoodsElevatorPrice : 0)
                , '€')}`
              : '',
            value1Type: 'line',
          },
          // {
          //   title: '',
          //   value: `+${formatPrice(hourlyPrice, '€')} ${confirmAppt.moving.eachAdditionalLabel}`, // `+${formatPrice(context.selectedData.pickupHelpPrice, '€')} ${confirmAppt.moving.eachAdditionalLabel}`,
          // },
          {
            title: '',
            value: confirmAppt.moving.excludingVATLabel, // 'Excluding VAT',
          },
        ];
      } else { // Basic
        data = [
          {
            type: 'header',
            title: confirmAppt.moving.basicServiceLabel,
          },
          getTruckItem(isFree),
        ];

        sumData = [
          {
            type: 'header',
            title: confirmAppt.moving.title,
            value: isFree ? confirmAppt.moving.freeLabel : '',
            valueType: 'green',
            value1: `${formatPrice(
              context.selectedData.movingBasePrice
              , '€')}`,
            value1Type: isFree ? 'line' : 'common',
          },
          {
            title: '',
            value: confirmAppt.moving.excludingVATLabel, // 'Excluding VAT',
          },
        ];
      }
    } else if (context.selectedData.help === 'DIY') { // DIY
      data = [
        {
          type: 'header',
          title: confirmAppt.moving.DIYServiceLabel,
        },
        {
          title: confirmAppt.moving.DIYLabel,
          value: confirmAppt.moving.freeLabel,
          valueType: 'green',
        },
      ];
      sumData = [
        {
          type: 'header',
          title: confirmAppt.moving.title,
          value: confirmAppt.moving.freeLabel,
          valueType: 'green',
        },
        {
          title: '',
          value: confirmAppt.moving.excludingVATLabel, // 'Excluding VAT',
        },
      ];
      editStep = 4;
    }

    const result = [
      {
        type: 'common',
        data,
        editStep,
      },
      {
        type: 'sum',
        data: sumData,
      },
    ];

    return result;
  }


  function getStorageDiscountPrice() {
    const monthlyStorage = context.selectedData.monthlyStorage;
    let result = '';
    // if (monthlyStorage === 'm8') {
    //   result = `${confirmAppt.moving.freeLabel} ${confirmAppt.storage.firstMonthLabel}`;
    // } else if (monthlyStorage === 'm4') {
    //   result = `${formatPrice(monthlyStoragePrice * 0.5, '€')} ${confirmAppt.storage.firstMonthLabel}`;
    // }

    return result;
  }


  if (context.selectedData.monthlyStorage === 'm4') {
    monthlyStorage = 4;
  } else if (context.selectedData.monthlyStorage === 'm8') {
    monthlyStorage = 8;
  }

  if (context?.content?.prices?.prices?.plans) {
    protectionPrice = context?.content?.prices?.prices?.protectionPlans[context?.selectedData?.protection]?.price || 0;
    const tmp = context?.content?.prices?.prices?.plans.filter(plan => {
      return plan.size == unit
    })

    if (tmp && tmp[0]) {
      unitPrice = tmp[0].storagePrice;
      unitVolume = tmp[0].volume;
      workers = tmp[0].workers;
      unitDescripton = tmp[0].description;
    }
  }

  function getServiceItems() {
    const coverageValue = context?.content?.prices?.prices?.protectionPlans[context.selectedData.protection]?.coverageValue;
    const serviceItems = [
      {
        title: confirmAppt.storage.title,
        data: [
          {
            type: 'common',
            data: [
              {
                type: 'header',
                title: unitDescripton, // unit,
                hoverVolume: unitVolume,
                volume: unitVolume,
                value: `${formatPrice(monthlyStoragePrice, '€')}/${confirmAppt.storage.perMonth}`,
                // valueType: (monthlyStorage === 4 || monthlyStorage === 8) ? 'line' : 'common',
                valueType: 'common',
              },
              {
                title: `${monthlyStorage} ${confirmAppt.storage.minMonthsLabel}`, // 4 months min duration
                value: getStorageDiscountPrice(), // `${formatPrice(monthlyStoragePrice, '€')} ${confirmAppt.storage.firstMonthLabel}`,
                valueType: 'green',
              },
            ],
            editStep: 2,
          },
          {
            type: 'common',
            data: [
              {
                type: 'header',
                title: confirmAppt.storage.protectionLabel,
                value: `${formatPrice(protectionPrice, '€')}/${confirmAppt.storage.perMonth}`,
              },
              {
                title: `Έως €${coverageValue || 1}${coverageValue ? '' : '/kg'} ${confirmAppt.storage.coverageLabel}`,
                value: '',
              },
            ],
            editStep: 17,
          },
          {
            type: 'sum',
            data: [
              {
                type: 'header',
                title: confirmAppt.storage.title,
                value:  `${formatPrice(monthlyStoragePrice + protectionPrice, '€')}/${confirmAppt.storage.perMonth}`,
              },
              {
                title: '',
                value: confirmAppt.storage.includingVATLabel, // 'Including VAT',
              },
            ],
          },
        ],
      },
    ];
  
    if (context.selectedData['help'] === 'pickup') {
      serviceItems.push({
        title: confirmAppt.moving.title,
        data: getMovingData(),
      });
    }

    return serviceItems;
  }

  function getDeliveryInfo() {
    const deliveryInfo = {
      title: confirmAppt.info.title,
      data: [
        {
          type: 'common',
          data: [
            {
              type: 'header',
              title: confirmAppt.info.dateTimeLabel,
              value: context.selectedData.date ? format(new Date(context.selectedData.date), 'd MMM, EEE') : context.selectedData.datePeriod, // '3 Feb, Wed',
            },
            {
              title: '',
              value: context.selectedData.time
                ? `${format(context.selectedData.time, 'hh:mmaaa')} - ${getAppointmentArrivalEnd(context.selectedData.time)}` // '09:00am - 12:00pm',
                : '',
            },
          ],
          editType: 'info',
          editStep: 21,
        },
      ],
    };

    if (context.selectedData['help'] === 'pickup' && context.selectedData.address?.address) {
      deliveryInfo.data.push({
        type: 'common',
        data: [
          {
            type: 'header',
            title: confirmAppt.info.pickupAddressLabel,
            value: context.selectedData.address?.address,
          },
          {
            title: '',
            value: '',
          },
        ],
        editType: 'info',
        editStep: 6,
      });
    }

    if (context.selectedData['help'] === 'pickup' && context.selectedData['shipment-destinationaddress']?.address) {
      deliveryInfo.data.push({
        type: 'common',
        data: [
          {
            type: 'header',
            title: confirmAppt.info.destinationAddressLabel,
            value: context.selectedData['shipment-destinationaddress']?.address,
          },
          {
            title: '',
            value: '',
          },
        ],
        editType: 'info',
        editStep: 12,
      });
    }

    deliveryInfo.data.push({
      type: 'common',
      data: [
        {
          type: 'header',
          title: confirmAppt.info.contactLabel,
          value: context.selectedData.contact?.name,
        },
        {
          title: '',
          value: `+${context.selectedData.contact?.phone}`,
        },
        {
          title: '',
          value: context.selectedData.contact?.email,
        },
      ],
      editType: 'info',
      editStep: 20,
    })

    return deliveryInfo;
  }

  return (
    <>
      <Section step={props.step} title={confirmAppt.header}>
        <div className={classes.formContainer}>
          <div className="details-card">
            {getServiceItems().map((item, index) => (
              <DetailsServiceItem
                key={`service-item-${index}`}
                data={item}
              />
            ))}
          </div>
          <div className="details-card">
            <DetailsServiceItem
              data={getDeliveryInfo()}
            />
          </div>
        </div>
      </Section>

      <LoadingModal
        title={context?.content?.misc?.loaderTitle}
        description={context?.content?.misc?.loaderDescription}
        icon={context?.content?.misc?.logo?.fields?.file.url}
        open={!!context.selectedData.bookingInProcess}
      />
    </>
  )
}

ConfirmDetails = React.memo(ConfirmDetails, (prevProps, nextProps) => {
  console.log(JSON.stringify(prevProps) == JSON.stringify(nextProps), 'errr')
})

export default ConfirmDetails;
