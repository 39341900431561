import React, { Component, useContext } from "react";
import AppContext from "../../AppContext";
import { Section } from "../../components/Section/Section";

function Intro(props) {
  const context = useContext(AppContext);
  
  return (
    <Section
      step={props.step}
      intro={true}
      name="intro"
      title={context.content && context.content.intro && context.content.intro.header}
      subtitle={
        context.content && context.content.intro && context.content.intro.subheader
      }
    ></Section>
  );
}

export default React.memo(Intro);
