import React, { useContext } from 'react';
import { Section } from '../../components/Section/Section';
import QuoteCard from '../../components/QuoteCard/QuoteCard';
import CustomQuoteCard from '../../components/QuoteCard/CustomQuoteCard';
import AppContext from '../../AppContext'

import './CustomizeQuote.css';

export const helpNames = {
  'curbside': 'Curbside pickup',
  'full': 'Full service',
}

export default function CustomizeQuote(props) {
  const context = useContext(AppContext);

  if (!context.content?.customizeQuote?.services?.services.length ||
      !context.content?.helpChoice ||
      !context.content?.monthlyStorage ||
      !context.content?.prices
    ) {
    return null;
  }

  const serviceContents = context.selectedData.serviceContents;
  const helpType = context.selectedData.help;

  const movingService = serviceContents ? serviceContents.filter(content => content.type === "moving") : [];
  const selectedMonthlyStorage = context.selectedData.monthlyStorage;

  let selectedMonthlyStorageService = context.content.monthlyStorage.services.services.filter(service => service.type === selectedMonthlyStorage);
  selectedMonthlyStorageService = selectedMonthlyStorageService ? selectedMonthlyStorageService[0] : {};

  let storageServiceIcon = context.content.monthlyStorage.images.filter(image => image?.fields?.title === 'Storage');
  storageServiceIcon = storageServiceIcon ? storageServiceIcon[0]?.fields?.file?.url : "";

  let selectedPricePlan = context.content.prices.prices.plans.filter(plan => plan.size === context.selectedData.unit);
  selectedPricePlan = selectedPricePlan ? selectedPricePlan[0] : {};

  if (!serviceContents) {
    return null;
  }

  function onSelect(type, value) {
    if (type === 'DIY') {
      context.setStep(4);
    } else {
      const _serviceContents = [...serviceContents];
      if (type === 'moving') {
        let selectedObj = {
          'needMoving': value[0],
        };
        _serviceContents[0].selected = value;
        context.setSelectedObject({
          ...selectedObj,
          serviceContents: _serviceContents,
        });
      } else if (type === 'pickup') {
        let selectedObj = {
          'needHelpPickup': value[0],
        };

        if (!value[0]) {
          selectedObj = {
            ...selectedObj,
            'needHelpPack': false,
          };
          _serviceContents[2].selected = [false];
        }
        _serviceContents[1].selected = value;
        context.setSelectedObject({
          ...selectedObj,
          serviceContents: _serviceContents,
        });
      } else if (type === 'packing') {
        let selectedObj = {
          'needHelpPack': value[0],
        };
        if (value[0]) {
          selectedObj = {
            ...selectedObj,
            'needHelpPickup': true,
          };
          _serviceContents[1].selected = [true];
        }
        _serviceContents[2].selected = value;
        context.setSelectedObject({
          ...selectedObj,
          serviceContents: _serviceContents,
          help2: value[0] ? 'full' : 'basic',
        });
      } else { // goodsElevator
        const prefix="shipment-destination";
        _serviceContents[3].selected = value;
        context.setSelectedObject({
          'goodsElevator': `goods-elevator-answer-${value[0] ? 'yes' : 'no'}`,
          'shipment-destinationgoodsElevator': `${prefix}goods-elevator-answer-${value[1] ? 'yes' : 'no'}`,
          serviceContents: _serviceContents,
        });
      }
    }
  }

  function onSelectCustomQuote(step) {
    if (helpType === 'DIY') {
      return false;
    } else {
      context.setStep(step);
    }
  }

  console.log('serviceContents ======>', serviceContents);

  return (
    <Section
      className="CustomizeQuote"
      step={props.step}
      title={context.content?.customizeQuote?.header}
    >
      <div className="row">
        <div className="col-half">
          {serviceContents.map((content, i) => {
            if ( content.type === 'moving' || content.type === 'pickup' || content.type === 'goodsElevator' ) {
              return null;
            }
            return (
              <QuoteCard
                key={`CustomizeQuote-QuoteCard-${i}`}
                {...content}
                onSelect={onSelect}
              />
          )})}
        </div>
        <div className="col-half">
          {
            helpType === 'DIY' ?
            null
            :
            <React.Fragment>
              {
                selectedMonthlyStorageService ?
                <CustomQuoteCard
                  onSelect={onSelectCustomQuote}
                  {...selectedMonthlyStorageService}
                  {...selectedPricePlan}
                  icon={storageServiceIcon}
                />
                :
                null
              }
              {
                movingService && movingService.length ?
                <QuoteCard
                  {...movingService[0]}
                  onSelect={onSelect}
                />
                :
                null
              }
            </React.Fragment>
          }
        </div>
      </div>
    </Section>
  )
}
