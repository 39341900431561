import React from 'react'

import './Card.css'

const Card = ({
    header,
    subtitle,
    img,
}) => {

    return (
        <div className='container'>
            <img src={img} style={{width: '50px'}} />
            <div className='textContainer'>
                <p className='TypographyTitle'>{header}</p>
                <p className='Card__description'>{subtitle}</p>
            </div>
        </div>
    )
}

export default Card
