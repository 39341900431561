import React, { useRef } from 'react';

import './CountPicker.css';

export function CountPicker(props) {
	const inputRef = useRef();

	function changeValue(increment) {
		const input = inputRef.current;
		input.value = Math.max(Math.min(input.max, ~~input.value + increment), input.min);

		props.onChange && props.onChange(props.name, ~~input.value);
	}

	function onButtonChange(value) {
		props.onChange && props.onChange(props.name, value);
	}

	return (
		<div className="CountPicker">
			<div className="CountPicker__inner">
				<div className="CountPicker__title">{props.title || props.name}</div>
				{
					props.img ?
						<div className="CountPicker__icon"><img src={props.img} style={{ width: props.elevator ? '100%' : '30%' }} /></div>
						: <div className="CountPicker__icon"><img src={props.icon} style={{ width: props.elevator ? '100%' : '30%' }} /></div>
				}
				{props.maxCount === 1 &&
					<button
						onClick={() => onButtonChange(!props.value)}
						className={`CountPicker__button${props.value ? ' CountPicker__button--selected' : ''}`}
					>
						{props.value ? 'Remove' : props.name ? 'Select' : 'Add'}
					</button>}
				{props.maxCount !== 1 && <div className="CountPicker__picker" onChange={() => changeValue(0)}>
					<div className="CountPicker__control CountPicker__minus" onClick={() => changeValue(-1)}>-</div>
					<div className="CountPicker__inputWrap">
						<input ref={inputRef} type="tel" className="CountPicker__input" disabled defaultValue={props.value || 0} min={0} max={props.maxCount} />
					</div>
					<div className="CountPicker__control CountPicker__plus" onClick={() => changeValue(1)}>+</div>
				</div>}
			</div>
		</div>
	)
}
