import React, { useContext, useEffect, useState, useRef } from "react";
import { Section } from "../../components/Section/Section";
import { Input } from "../../components/Input/Input";
import AppContext from "../../AppContext";
import { Form, Formik } from "formik";
import GooglePlacesAutocomplete from "react-google-places-autocomplete";
import { components } from 'react-select';
import './index.css';

const LocationIcon = () => (
	<svg
		width="18"
		height="20"
		viewBox="0 0 18 20"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			d="M8.75 0C3.925 0 0 3.55169 0 7.91672C0 12.7076 6.08333 18.1551 7.95 19.7101C8.41362 20.0966 9.08721 20.0966 9.55083 19.7101C11.4167 18.1534 17.5 12.7051 17.5 7.91672C17.5 3.55169 13.575 0 8.75 0Z"
			fill="#000000"
		></path>
	</svg>
);
const _DropdownIndicator = ({ innerProps }) => <div>{<LocationIcon />}</div>;

export default function Address(props) {
	const context = useContext(AppContext);
	const [address, setAddress] = useState(null);
	const [inputValue, setInputValue] = useState("");
	const selectRef = useRef();
	const addressRef = useRef(null);

	useEffect(() => {
		context.setCanContinue(props.step, false);
	}, []);

	useEffect(() => {
		if (selectRef.current) {
			selectRef.current.select.select.inputRef.style.opacity = 1;
		}
	}, [address]);

	const MenuList = (props) => (
		<components.MenuList {...props}>
			<div style={{
				fontSize: 12,
				lineHeight: '17px',
				paddingLeft: 20,
				paddingBottom: 7,
				paddingTop: 5,
				color: 'rgb(117, 117, 117)',
				borderBottom: '1px solid rgb(235, 236, 237)',
				fontWeight: 500,
			}}>{context?.content?.address?.selectAnAddress}</div>
			{props.children}
		</components.MenuList>
	);

	const onInputChange = (inputValue, { action }) => {
		if (action === "input-blur") {
			setInputValue(addressRef.current ? addressRef.current.label : "");
			// setInputValue(address ? address.label : "");
		}
		
		else if (action === "input-change") {
			setInputValue(inputValue);
		}
	};

	const onChange = (option) => {
		setAddress(option);
		addressRef.current = { ...option };
		setInputValue(option ? option.label : "");
		const addressValue = { address: option?.value?.description || '' };
		context.setSelectedData(`${props.prefix || ''}address`, addressValue);
	};

	const onFocus = () => {
		if (address && selectRef.current) {
			selectRef.current.select.select.inputRef.select();
		}
	}

	const selectProps = {
		address,
		menuIsOpen: true,
		ref: selectRef,
		value: address,
		inputValue,
		onChange,
		onInputChange,
		onFocus,
		controlShouldRenderValue: false,
		placeholder: context?.content?.address?.startTyping,
		components: {
			MenuList,
			DropdownIndicator: _DropdownIndicator,
		},
		noOptionsMessage: () => null,
		styles: {
			indicatorsContainer: (provided) => ({
				...provided,
				position: 'absolute',
				top: '0px',
				bottom: '0px',
				left: '0px',
				borderTopLeftRadius: 2,
				borderBottomLeftRadius: 2,
				alignSelf: 'center',
				width: 46,
				padding: '11px 0',
				justifyContent: 'center',
				background: 'rgb(235, 236, 237)',
			}),
			loadingIndicator: () => ({
				display: 'none',
			}),
			indicatorSeparator: (provided) => ({
				...provided,
				display: 'none',
			}),
			input: (provided) => ({
				...provided,
			}),
			control: (provided, state) => {
				let borderColor = state.isFocused ? 'rgb(117, 117, 117)' : 'rgb(235, 236, 237)';
				if (address) {
					borderColor = 'rgb(9, 126, 119)';
				}


				return {
					...provided,
					display: 'flex',
					flexFlow: 'row-reverse nowrap',
					paddingLeft: '46px',
					border: `2px solid ${borderColor}`,
					cursor: 'text',
					boxShadow: 'none',
					'&:hover': {
						boxShadow: 'none',
					},
					'&:active': {
						boxShadow: 'none',
					},
					'&:focus': {
						boxShadow: 'none',
					},
				}
			},
			valueContainer: (provided) => ({
				...provided,
				padding: '11px 8px'
			}),
			menu: (provided) => ({
				...provided,
				marginTop: 0,
				boxShadow: 'rgba(0, 0, 0, 0.1) 0px 4px 12px',
			}),
			menuList: (provided) => ({
				...provided,
				overflowX: 'hidden',
				maxHeight: 'unset',
			}),
			option: (provided, props) => ({
				...provided,
				display: 'inline-block',
				margin: '4px',
				color: 'rgb(78, 78, 78)',
				fontSize: 16,
				fontWeight: 500,
				padding: '10px 16px',
				background: 'transparent',
				'&:hover': {
					background: 'rgb(235, 236, 237)',
				}
			}),
			singleValue: (provided) => ({
				...provided,
				color: '#000',
			}),
		},
	}
	if (context.selectedData['shipment-destinationaddress'])
		selectProps.defaultValue = { label: context.selectedData['shipment-destinationaddress']?.address, value: context.selectedData['shipment-destinationaddress']?.address }
	return (
		<>
			<Formik
				initialValues={{ apartment: "", zip: "" }}
				validate={(values) => {
					const errors = {};
					if (!address.value.description) {
						errors.address = "Required";
					}
					context.setStep(props.step);
					context.setCanContinue(props.step, !Object.keys(errors).length);
					return errors;
				}}
				onSubmit={(values, { setSubmitting }) => {
					const [step, substep] = props.step.toString().split('-');
					context.setStep(`${step}-${~~substep + 1}`);
					setSubmitting(false);
				}}
			>
				{({
					values,
					errors,
					touched,
					handleChange,
					handleBlur,
					handleSubmit,
					isSubmitting,
				}) => (
						<Section
							canContinue={address != null}
							step={props.step}
							title={
								context && context.content && context.content.whereToShip && context.content.whereToShip.header
							}
							nextButtonType="submit"
							className="ShipmentDestinationAddress"
							onNextClick={handleSubmit}
						>
							<Form onSubmit={handleSubmit}>
								<div className="InputLabel">
									{
										context && context.content && context.content.address && context.content.address.address
									}
								</div>
								<GooglePlacesAutocomplete
									apiKey="AIzaSyCTkDJKP9EVYxLqPcBp2hqVtYnr2b1qOVM"
									selectProps={selectProps}
								/>
								<span className="success_label">
									{(address || selectProps.defaultValue) && context?.content?.address?.weveLocatedThisAddress}
								</span>
							</Form>
						</Section>
					)}
			</Formik>
		</>
	);
}
