import React, { useState } from 'react';
import { Typography } from '@material-ui/core';
import Modal from '@material-ui/core/Modal';
import { makeStyles } from '@material-ui/core/styles';
import GoogleMapReact from 'google-map-react';
import { ReactComponent as UnCheckMarkImage } from '../../assets/svgs/uncheckedmark.svg';

function getModalStyle() {
  const top = 50 ;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

const useStyles = makeStyles(theme => ({
  paper: {
    position: 'absolute',
    minWidth: 320,
    maxWidth: 500,
    // backgroundColor: 'white',
    outline: 'none',
    padding: '24px',
    borderRadius: 4,
  },

  title: {
    fontSize: '2rem',
    fontWeight: 900,
    fontFamily: 'Averta-Extrabold, Source Sans Pro, sans-serif',
    marginBottom: 24,
    marginTop: 20,
  },

  map: {
    width: '100%',
    backgroundColor: '#f1f1f1',
  },

  address: {
    background: 'rgb(249, 249, 249)',
    borderRadius: 4,
    padding: 16,
    marginTop: 24,
  },

  label: {
    fontSize: '.875rem',
    color: '#454545',
    fontFamily: 'Averta-Bold, Source Sans Pro, sans-serif',
  },

  storeImage: {
    width: '100%',
  }
}));

const Marker = props => {
  return <div className="pin"></div>
}

function MapModal(props) {
  const {
    open,
    title,
    label,
    address,
    lat,
    lng,
    storeImage,
    onClose,
  } = props;
  const classes = useStyles();
  const [modalStyle] = useState(getModalStyle);

  function renderMarkers(map, maps) {
    let marker = new maps.Marker({
      position: {
        lat, lng
      },
      map,
      title: 'Hello',
    });

    return marker;
  }

  return (
    <Modal
      aria-labelledby="Modal"
      aria-describedby="Modal"
      open={open}
      BackdropProps={
        { style: { backgroundColor: 'rgba(0, 0, 0, 0.3)' }}
      }
    >
      <div
        style={{
          ...modalStyle,
          backgroundColor: 'white',
        }}
        className={classes.paper}
      >
        <div onClick={onClose}>
          <UnCheckMarkImage width={16} height={16}/>
        </div>
        {title && (
          <Typography className={classes.title} align="center">
            {title}
          </Typography>
        )}
        <div className={classes.map}>
          {/* <GoogleMapReact
            bootstrapURLKeys={{ key: 'AIzaSyCTkDJKP9EVYxLqPcBp2hqVtYnr2b1qOVM' }}
            defaultCenter={{ lat, lng }}
            defaultZoom={11}
            onGoogleApiLoaded={({map, maps}) => renderMarkers(map, maps)}
          >
            <Marker lat={lat} lng={lng} />
          </GoogleMapReact> */}
          <img src={storeImage} className={classes.storeImage} />
        </div>
        <div className={classes.address}>
          {address.map((item, index) => (
            <Typography key={`address-${index}`} className={classes.label}>
              {item}
            </Typography>
          ))}
        </div>
      </div>
    </Modal>
  );
}

export default MapModal;
