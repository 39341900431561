import React, { useContext } from 'react';
import { Section } from '../../components/Section/Section';
import AppContext from '../../AppContext';
import { CountPicker } from '../../components/CountPicker/CountPicker';

import './Elevator.css';

const Elevator = (props) => {
  const context = useContext(AppContext);
  const elevatorData = context.selectedData['elevator'] || {};

  function moveToNext() {
    const nextStep = 12;
    context.setStep(nextStep);
  }

  function onChange(name, value) {
    const _elevatorData = { ...elevatorData };

    if (value) { // unselect the selected items for signle selection
      const keys = Object.keys(_elevatorData);
      for (let i = 0; i < keys.length; i++) {
        _elevatorData[keys[i]] = false;
      }
    }

    context.setSelectedData('elevator', {
      ..._elevatorData,
      [name]: value,
    });

    moveToNext();
  }

  return (
    <Section
      canContinue={Object.values(elevatorData).length && Object.values(elevatorData).some(v => v)}
      className="ElevatorType"
      step={props.step}
      title={context.content?.whatElevatorType?.header}
    >
      <div className='wrapper-elevator'>
        {
          context.content &&
          context.content.whatElevatorType &&
          context.content.whatElevatorType.cards &&
          context.content.whatElevatorType.cards.cards.map((item, i) => {
            const selected = context.selectedData['elevator'] || {};
            const value = selected[item.name];
            return (
              <CountPicker
                {...item}
                key={`Elevator-CountPicker-${i}`}
                elevator
                maxCount={1}
                value={value}
                onChange={onChange}
                img={
                  context.content.whatElevatorType.images[i] &&
                  context.content.whatElevatorType.images[i].fields.file.url
                }
              />
            )
          })
        }
      </div>
    </Section>
  )
}

export default Elevator;
