import React from 'react';

import './Input.css';

export function Input(props) {
  return (
    <>
      {props.label && <div className="InputLabel">{props.label}</div>}
      <div className={`Input${props.icon ? ' Input--withIcon' : ''}`} >
        {props.icon && <div className="Input__icon">{props.icon}</div>}
        <input
          value={props.value}
          name={props.name}
          placeholder={props.placeholder}
          onChange={props.onChange}
          className="Input__input"
        />
      </div>
      {props.error && <span className="InputError">{props.error}</span>}
    </>
  )
}
