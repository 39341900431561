import React, { useContext, useState } from 'react';
import { Section } from '../../components/Section/Section';
import CardForService from '../../components/Card/CardForService';
import AppContext from '../../AppContext';

import './HelpChoice.css';


export default function HelpChoice2(props) {
  const context = useContext(AppContext);

  if (!context.content?.customizeQuote?.services?.services.length ||
    !context.content?.helpChoice2?.services?.services.length ||
    !context.selectedData.serviceContents
  ) {
    return null;
  }

  const selectedPlan = context.selectedData.help2;
  const serviceContents = context.selectedData.serviceContents;

  const services = context.content.helpChoice2.services.services.map((service, i) => {
    let price = null;
    if (service.type === 'full') {
      serviceContents.forEach(sc => {
        if (sc.type === 'packing' || sc.type === 'moving') {
          sc.price.forEach(scp => {
            if (scp.value) {
              price += scp.value;
            }
          })
        }
      })
    }

    return {
      ...service,
      selected: selectedPlan === service.type,
      name: service.type,
      effect: service.effect,
      icon: context.content.helpChoice2.images[i].fields.file.url,
      price
    };
  }) || [];

  function onSelect(name) {
    context.setSelectedObject({
      help2: name,
    });
    if (context.step > props.step)
      return;
  }

  return (
    <>
      <Section
        canContinue={!!selectedPlan}
        className="HelpChoice"
        step={props.step}
        title={context && context.content && context.content.helpChoice2 && context.content.helpChoice2.header}
      >
        {services.map((service, i) => {
          return (
            <CardForService
              key={`HelpChoice2-Card-${i}`}
              {...service}
              size={service.size}
              largeVertical={true}
              onSelect={onSelect}
            />
          )
        })}
      </Section>
    </>
  )
}
