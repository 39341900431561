import React, { useContext, useEffect } from 'react';
import { Section } from '../../components/Section/Section';
import AppContext from '../../AppContext';
import { Form, Formik } from 'formik';
import { Input } from '../../components/Input/Input';
import { PhoneInput } from '../../components/Input/PhoneInput';
import './index.css';

export default function ContactInfo(props) {
    const { bindSubmitContactForm } = props;
    const context = useContext(AppContext);

    let lockStoreIcon = context.content?.contactInfo?.images.filter(image => image?.fields?.title === 'lockStore');
    lockStoreIcon = lockStoreIcon ? lockStoreIcon[0]?.fields?.file?.url : "";

    useEffect(() => {
        context.setCanContinue(props.step, false);
    }, []);

    return (
        <Formik
            enableReinitialize
            initialValues={{
                name: context?.selectedData?.contact?.name || '',
                phone: context?.selectedData?.contact?.phone || '',
                email: context?.selectedData?.contact?.email || '',
            }}
            validate={values => {
                const errors = {};
                if (!values.name) {
                    errors.name = 'Required';
                }

                if (!values.phone) {
                    errors.phone = 'Required';
                }

                if (!values.email) {
                    errors.email = 'Required';
                }

                context.setStep(props.step);
                context.setCanContinue(props.step, !Object.keys(errors).length);

                return errors;
            }}
            onSubmit={(values, { setSubmitting }) => {
                context.setSelectedData('contact', values);
                context.setStep(props.step + 1);
                setSubmitting(false);
            }}
        >
            {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                setValues,
                isSubmitting,
            }) => {
                bindSubmitContactForm(handleSubmit);
                return (
                    <Section
                        step={props.step}
                        className="ContactInfo"
                        title={context.content?.contactInfo?.header}
                        nextButtonType="submit"
                        onNextClick={handleSubmit}
                    >
                        <p className="SectionSubheader">{context.content?.contactInfo?.subheader}</p>
                        <Form onSubmit={handleSubmit}>
                            <Input
                                value={values.name}
                                error={errors.name}
                                name="name"
                                label="Πώς ονομάζεστε;"
                                onChange={handleChange}
                            />
                            <PhoneInput
                                country={'gr'}
                                value={values.phone}
                                error={errors.phone}
                                name="phone"
                                label="Κινητό"
                                onChange={(value) => {
                                    handleChange(value)
                                    setValues({
                                        ...values,
                                        phone: value,
                                    });
                                }}
                            />
                            <Input
                                value={values.email}
                                error={errors.email}
                                name="email"
                                label="Email"
                                onChange={handleChange}
                            />
                        </Form>

                        <div className="help-block">
                            <div className="help-block__note">
                                <img className="" src={lockStoreIcon} />
                                <div dangerouslySetInnerHTML={{ __html: context.content?.contactInfo?.infobox }} />
                            </div>
                        </div>
                    </Section>
                )}
            }
        </Formik >
    );
}
