import React, { useContext, useEffect } from 'react';
import { Element } from 'react-scroll';
import { useInView } from 'react-intersection-observer';
import AppContext from '../../AppContext';
import './Section.css';

export function Section(props = {}) {
	const context = useContext(AppContext);
	const [ref, inView] = useInView({
		threshold: props.step === 0 ? 1 : 0.1,
	});
	useEffect(() => context.onVisibilityChange(inView, props.step), [])
	useEffect(() => {
		if (context.onVisibilityChange) {
			context.onVisibilityChange(inView, props.step)
		} else {
			console.warn('Pass onVisibilityChange prop to component')
		}
	}, [inView, props.step]);

	const [step, substep] = props.step.toString().split('-');
	const [currentStep, currentSubstep] = context.step.toString().split('-');

	if (~~step > ~~currentStep || (
		step === currentStep && ~~substep > ~~currentSubstep
	)) {
		return null;
	}

	const focused = props.step + '' === context.focusedStep + '';
	let canContinue = context.canContinue[props.step] === undefined ? true : context.canContinue[props.step];

	if (props.canContinue !== undefined) {
		canContinue = props.canContinue;
	}

	return (
		<Element id={`step-${step}${substep ? `-${substep}` : ''}`}>
			<section className={`
				Section
				${props.className || ''}
				${props.intro ? ' Section--intro' : ''}
				${focused ? ' Section--focused' : ''}
				${props.hidden ? 'Section--hidden': ''}
			`}>
				<div className="Section__inner">
					<div className="Section__header">
						{props.title && <h1 className="Section__title">{props.title}</h1>}
						{props.breaklineTitle && <h1 style={{ marginTop: '-30px' }} className="Section__title">{props.breaklineTitle}</h1>}
						{props.breaklineTitle1 && <h1 style={{ marginTop: '-30px', marginBottom: 40 }} className="Section__title">{props.breaklineTitle1}</h1>}
					</div>
					<div className="Section__content" ref={ref}>
						{props.subtitle && <h3 className="Section__subtitle">{props.subtitle}</h3>}
						{props.description && <p className="Section__description">{props.description}</p>}
						<div className="Section__children">
							{props.children}
						</div>
						{props.hasSkipButton && (
							<div className="Section__buttons">
								<div
									onClick={_ => context.setStep(~~step + 1)}
									style={{
										fontWeight: 600,
										color:'rgb(9, 126, 119)',
										marginRight: '20px',
										cursor: 'pointer',
									}}
								>
									{context?.content?.protection?.skipForNow}
								</div>
							</div>
						)}
					</div>
				</div>
			</section>
		</Element>
	)
}
