import React, { useContext } from 'react';
import { Button } from '../Button/Button';
import { Title } from '../Typography';
import { formatPrice } from '../../Utils';
import AppContext from '../../AppContext';
import './Card.css';

function Card(props) {
    const context = useContext(AppContext);
    const { selected, onSelect, largeVertical, perMonth, discountLabel } = props;
    let prices

    return (
        <div style={{display: 'flex', flexDirection:'column'}} className={`Card${selected ? ' Card--selected' : ''}${largeVertical ? ' Card--largeVertical' : ''}`}>
            <div className="Card__main">
                {props.icon && <div className="Card__left">
                    <img className="Card__icon" src={props.icon} />
                </div>}
                <div className="Card__content">
                    {props.label && <div className="Card__label">{props.label}</div>}
                    <Title>{props.title}</Title>
                    <div className="Card__description">{props.description}</div>
                    {props.hasContent && (
                        <div>
                            <Button size="small" onClick={props.onContentButtonClick} theme="clear">{props.storageLocation.button}</Button>
                        </div>
                    )}
                </div>
                
            </div>
            <div style={{marginTop: 'auto'}} className="Card__separator" />
            <div className="Card__footer">
                <div>
                    <div className="Card__price">
                        {
                            props.effect
                                ? (<div className="Card__effect">{props.effect}</div>)
                                : (
                                    <>
                                        { !props.protectionCard && <span className="bold" style={{fontSize:'16px'}}>{prices}</span>}
                                        <div>
                                            {
                                                props.protectionCard
                                                    ? <span className="bold" style={{fontSize:'16px'}}>{formatPrice(props.price, '€')}{perMonth}</span>
                                                    : context?.content?.helpMoving?.moveInFee
                                            }
                                        </div>
                                    </>
                                )
                        }
                    </div>
                    {props.discountLabel && <div className="Card__discount">{discountLabel}</div>}
                </div>

                {!selected && <Button size="small" onClick={() => onSelect && onSelect(props.name, prices)} theme='secondary'>Select</Button>}
                {selected && <Button size="small" onClick={() => onSelect && onSelect(props.name, prices)} theme='primary'>Selected</Button>}
            </div>
        </div>
    )
}

export default Card;
