import React, { useState, useContext } from 'react'

import { Section } from '../../components/Section/Section';
import { Radio } from '../../components/Radio/Radio'
import AppContext from "../../AppContext";

const MovingOutOrStoringExtra = ({
    step,
    first,
    second
}) => {
    const context = useContext(AppContext);
    const [moving, setMoving] = useState(false)
    const [notMoving, setNotMoving] = useState(false)

    function moveToNext() {
        const nextStep = 17;
        context.setStep(nextStep);
    }

    return (
        <Section canContinue={moving === true || notMoving === true} step={step} title={
            context.content && context.content.movingOutOr && context.content.movingOutOr.header
        }>
            <div onClick={() => {
                setMoving(true)
                setNotMoving(false)
                context.setSelectedData('moveOutOrStoreExtra', 'moving')
                moveToNext();
            }}
            >
                <Radio id='movingOut'
                    checked={moving || context.selectedData.moveOutOrStoreExtra == 'moving'}
                    onChange={() => {
                    }}
                >
                    {
                        context.content && context.content.movingOutOr && context.content.movingOutOr.movingOut
                    }
                </Radio>
            </div>
            <div
                onClick={() => {
                    setNotMoving(true)
                    setMoving(false)
                    context.setSelectedData('moveOutOrStoreExtra', 'storage')
                    moveToNext();
                }}
            >
                <Radio
                    id='storingExtra'
                    checked={notMoving || context.selectedData.moveOutOrStoreExtra == 'storage'}>
                    {
                        context.content && context.content.movingOutOr && context.content.movingOutOr.packagingMyself
                    }
                </Radio>
            </div>
        </Section>

    )
}

export default MovingOutOrStoringExtra
