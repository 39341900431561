import React, { useContext } from 'react';

import './QuoteCard.css';

import AppContext from '../../AppContext';
import { formatPrice } from '../../Utils';


function CustomQuoteCard(props) {
    const context = useContext(AppContext);
    const confirmAppt = context.content?.confirmAppt;

    const {
        selected,
        disabled,
        onSelect,
        customizeQuoteHeader,
        customizeQuoteIntro,
        customizeQuoteDescr,
        description,
        size,
        volume,
        base,
        additional,
        additionalPlus,
        commitmentTitle,
        icon,
    } = props;

    const perMonth = context?.content?.misc?.permonth;

    let price = base;
    let volumePlus = props.volumePlus || 8;
    if ( volume > 4 && volume <= volumePlus ) {
        price += additional * (volume - 4);
    } else if ( volume > volumePlus ) {
        price += additional * (volumePlus - 4);
        price += additionalPlus * (volume - volumePlus);
    }

    function handleOptionClick(e, step) {
        onSelect(step);
    }

    function handleCardClick() {

    }

    return (
        <div
            style={{display: 'flex', flexDirection:'row'}}
            className={`QuoteCard ${disabled && 'QuoteCard--disabled'}`}
            onClick={handleCardClick}
        >
            <div
                style={{display: 'flex', flex: 1, flexDirection:'column', borderTop: 0}}
                className="QuoteCard__inner"
            >
                <div className="QuoteCard__header">
                    <img src={require('../../assets/svgs/flash.svg')} className="QuoteCard__header__icon" />
                    <div className="QuoteCard__header__label">
                        { customizeQuoteIntro }
                    </div>
                    <img src={require('../../assets/svgs/flash.svg')} className="QuoteCard__header__icon" />
                </div>
                <div className="QuoteCard__main">
                    <div className="QuoteCard__left">
                        <img className="QuoteCard__icon" src={icon} />
                    </div>
                    <div className="QuoteCard__content">
                        <div className="QuoteCard__title">{ customizeQuoteHeader }</div>
                        <div className="QuoteCard__description">
                            { customizeQuoteDescr }
                        </div>
                    </div>
                </div>

                <div className="QuoteCard__footer">
                    <div className="QuoteCard__price">
                        <div className="QuoteCard__price-label">
                            { description } { volume }m<sup>3</sup> ({ size })
                        </div>
                    </div>
                    <div
                        className="QuoteCard__footer-button"
                        onClick={(e) => handleOptionClick(e, 2)}
                    >
                        Edit
                    </div>
                </div>

                <div className="QuoteCard__footer">
                    <div className="QuoteCard__price">
                        <div className="QuoteCard__price-label">
                            Από { formatPrice(price, '€') }{ perMonth }
                        </div>
                        <div className="QuoteCard__price-description">
                            { commitmentTitle }
                        </div>
                    </div>
                    <div
                        className="QuoteCard__footer-button"
                        onClick={(e) => handleOptionClick(e, 3)}
                    >
                        Edit
                    </div>
                </div>
            </div>

        </div>
    )
}

export default CustomQuoteCard;
