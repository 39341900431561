import React from 'react';

export function Radio(props) {
  return <>
    <input checked={props.checked} id={props.id} type="radio" name={props.name} className="TimePicker__radio" value={props.value} onChange={props.onChange} />
    <label htmlFor={props.id} className="TimePicker__label">
      <div className="TimePicker__radioIcon">
        <div className="TimePicker__radioIconDot" />
      </div>
      <div className="TimePicker__radioLabel">{props.children}</div>
    </label>
  </>
}
