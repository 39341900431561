import React from 'react';

import './Map.css';

function Map(props) {
    const {
        address,
        addressImage,
    } = props;

    return (
        <div className="Map">
            {addressImage && <img className="Map__image" src={addressImage} />}
            {address && address.length > 0 && (
                <div className="Map__content">
                    {address.map((item, index) => (
                        <div key={`Map-address-${index}`} className={`Map__address ${index === 0 && 'bold' }`}>{item}</div>
                    ))}
                </div>
            )}
        </div>
    )
}

export default Map;