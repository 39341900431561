import React, { useContext, useState } from 'react';
import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { ReactComponent as CheckMarkImage } from '../../assets/svgs/checkmark.svg';
import { ReactComponent as CheckBoxImage } from '../../assets/svgs/checkbox.svg'
import { ReactComponent as UnCheckBoxImage } from '../../assets/svgs/uncheckbox.svg'
import AppContext from '../../AppContext';
import './style.css';

const useStyles = makeStyles(theme => ({
  container: {
    padding: '23px 20px 16px',
    borderBottom: 'solid 1px #babec1',
  },
  
  sumContainer: {
    backgroundColor: '#e9f6f5',
    borderBottom: 'solid 1px #dceceb',
  },

  row: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    minHeight: 21,
  },
  bold: {
    fontFamily: 'Averta-Bold, Source Sans Pro, sans-serif',
  },

  titleContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    cursor: 'pointer',
    minWidth: 70,
    marginBottom: 4,

    '& > svg': {
      marginRight: 9,
    },
  },

  hoverTitleContainer: {
    minHeight: 28,
  },

  valueContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },

  value: {
    marginLeft: 8,
    textAlign: 'right',
  },

  grey: {
    color: '#454545',
    fontFamily: 'Averta-Regular, Source Sans Pro, sans-serif',
  },
  
  green: {
    color: '#00c6a6',
    fontFamily: 'Averta-Regular, Source Sans Pro, sans-serif',
  },

  sumValue: {
    fontSize: '1.375rem !important',
    fontFamily: 'Averta-Bold, Source Sans Pro, sans-serif',
  },

  lineThrough: {
    textDecoration: 'line-through',
  },

  checkbox: {
    marginLeft: 18,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },

  edit: {
    color: 'rgb(9, 126, 119)',
    textDecoration: 'none',
    marginTop: 4,
    width: 35,
    cursor: 'pointer',
  },

  marginEdit: {
    marginTop: -24,
  }
}))

const DetailsItem = ({ data, itemStyle, onSelect }) => {
  const classes = useStyles();
  const context = useContext(AppContext);
  const {
    type,
    key,
    editType,
    editStep,
    hideEdit,
    data: detailsItemData,
  } = data;
  const [hoverIndex, setHoverIndex] = useState(-1);

  return (
    <div
      className={`${classes.container} ${type === 'sum' && classes.sumContainer} ${itemStyle}`}
    >
      {detailsItemData.map((item, index) => (
        <div
          key={`details-item-${index}`}
          className={classes.row}
        >
          <div
            onMouseEnter={() => setHoverIndex(index)}
            onMouseLeave={() => setHoverIndex(-1)}
            className={`${classes.titleContainer} ${(item.hoverVolume) && classes.hoverTitleContainer}`}
          >
            {item.titleType === 'checked' && <CheckMarkImage />}
            <Typography
              className={`
                ${item.type === 'header' ? classes.bold : classes.grey}
              `}
              variant={item.type === 'header' ? 'body1' : 'subtitle2'}
            >
              {/* {
                (hoverIndex === index && item.hoverVolume)
                  ? <>{item.hoverVolume}m<sup>3</sup> ({item.title})</>
                  : item.title
              } */}
              {
                (item.volume)
                  ? <>{item.title} ({item.volume}m<sup>3</sup>)</>
                  : item.title
              }
            </Typography>
          </div>

          <div className={classes.valueContainer}>
            {item.value1 && (
              <Typography
                className={`
                  ${classes.bold}
                  ${item.value1Type === 'line' && classes.lineThrough}
                `}
                variant="subtitle2"
              >
                {item.value1}
              </Typography>
            )}
            <Typography
              className={`
                ${classes.value}
                ${type !== 'sum' && classes.bold}
                ${item.valueType === 'green' && classes.green}
                ${item.valueType === 'line' && classes.lineThrough}
                ${type === 'sum' && item.type !== 'header' && classes.grey}
                ${type === 'sum' && item.type === 'header' && classes.sumValue}
              `}
              variant="subtitle2"
            >
              {item.value}
            </Typography>

            {item.hasCheckbox && (
              <div
                className={classes.checkbox}
                onClick={() => {
                  if (onSelect) {
                    onSelect(key, item.selected);
                  }
                }}
              >
                {item.selected
                  ? <CheckBoxImage />
                  : <UnCheckBoxImage />
                }
              </div>
            )}
          </div>

        </div>
      ))}
      {type === 'common' && !hideEdit && (
        <div
          className={classes.edit}
          onClick={() => editStep && context.setStep(editStep)}
        >
          <Typography
            className={`
              ${classes.bold}
              ${editType === 'info' && classes.marginEdit}
            `}
            variant="subtitle2"
          >
            {context.content?.confirmAppt?.editLabel || 'Edit'}
          </Typography>
        </div>
      )}
    </div>
  );
}

export default DetailsItem;
