import React, {useContext} from 'react';

import './Header.css';
import AppContext from '../../AppContext';


export function Header() {
  const context = useContext(AppContext);
  return (
    <header className="Header">
      <div className="Header__inner">
        <div className="Header__logo">
          <img src={context && context.content && context.content.misc && context.content.misc.logo.fields.file.url} />
        </div>
      </div>
    </header>
  )
}
