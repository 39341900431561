import React, { useContext } from 'react';
import { Section } from '../../components/Section/Section';
import AppContext from '../../AppContext';


export default function WeWillContact(props) {
  const context = useContext(AppContext);

  return (
    <Section
      className="WeWillContactNote"
      step={props.step}
      title={context?.content?.weWillContact?.header}
      description={context?.content?.weWillContact?.subheader}
    />
  )
}
