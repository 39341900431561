import React, { useContext } from 'react';
import { Button } from '../Button/Button';
import { Title } from '../Typography';
import AppContext from '../../AppContext';
import './Card.css';

function CardForService(props) {
  const { selected, onSelect, largeVertical } = props;
  let prices;

  return (
    <div style={{display: 'flex', flexDirection:'column'}} className={`Card Card--forService ${selected ? 'Card--selected' : ''}${largeVertical ? 'Card--largeVertical' : ''}`}>

      <div className="Card__main">
        <div className="Card__heading">
          {props.icon && <div className="">
            <img className="Card__icon" src={props.icon} />
          </div>}
          <div className="Card__title">
            <div className="Card__tag">{props.tag}</div>
            <Title>{props.title}</Title>
            <div className="Card__description">{props.description}</div>
          </div>
        </div>

        <div className="Card__content">
          <p className="Card__content__header">{props.header}</p>
          <div className="Card__content__main">
            {
              (props.bulletPoints || []).map(bullet => (
                <div className="Card__content__bullet" key={bullet}><span>&#10003;</span>{bullet}</div>
              ))
            }
          </div>
        </div>

        {
          props.note && <div className="Card__content__note">{props.note}</div>
        }
      </div>

      <div style={{marginTop: 'auto'}} className="Card__separator" />

      <div className="Card__footer">
        <div>
          <div className="Card__price">
            {
              props.priceDescription && 
              <div className="">
                <div>{props.priceDescription}</div>
                <h3>&euro;{props.price}</h3>
              </div>
            }
          </div>
        </div>

        {!selected && <Button size="small" onClick={() => onSelect && onSelect(props.name, prices)} theme='secondary'>Select</Button>}
        {selected && <Button size="small" onClick={() => onSelect && onSelect(props.name, prices)} theme='primary'>Selected</Button>}

      </div>

    </div>
  )
}

export default CardForService;
