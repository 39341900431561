import React, { useContext } from 'react';
import { Section } from '../../components/Section/Section';
import AppContext from '../../AppContext';
import { CountPicker } from '../../components/CountPicker/CountPicker';

import './WhatsPacked.css';

export function WhatsPacked(props) {
  const context = useContext(AppContext);

  function onChange(name, value) {
    context.setSelectedData('packed', {
      ...context.selectedData['packed'],
      [name]: value,
    });
  }

  const selected = context.selectedData['packed'] || {};

  return (
    <Section
      canContinue={Object.values(selected).length && Object.values(selected).some(v => v)}
      className="WhatsPacked"
      step={props.step}
      title={context.content?.whatBeingPacked?.header}
    >
      <div
        style={{
          display: 'grid',
          gridTemplateColumns: '50% 50%',
          margin: '0 auto',
        }}
      >
        {context.content?.whatBeingPacked?.cats?.items && context.content.whatBeingPacked.cats.items.map((item, i) => {
          const selected = context.selectedData['packed'] || {};
          const value = selected[item.name];
          item.icon = context.content.whatBeingPacked.images[i].fields.file.url;

          return (
            <CountPicker
              {...item}
              key={`WhatsPacked-CountPicker-${i}`}
              value={value}
              onChange={onChange}
            />
          )
        })}
      </div>
    </Section>
  )
}
