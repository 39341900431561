import React, { useContext } from 'react'
import { Section } from '../../components/Section/Section';
import AppContext from '../../AppContext';
import './style.css'

const HowDidHear = ({ step }) => {
    const context = useContext(AppContext);
    const howDidHearData = context.selectedData['howDidHear'] || {};
    const stepData = context?.content?.howDidYouLearnAbout || {};
    const optionsData = [];
    if (stepData?.sources?.sources) {
        for (let i = 0; i < stepData.sources.sources.length; i++) {
            const item = stepData.sources.sources[i];
            optionsData.push({
                title: item,
                value: !!howDidHearData[item],
            });
        }
    }

    return (
        <Section
            canContinue={Object.values(howDidHearData).length && Object.values(howDidHearData).some(v => v)}
            step={step}
            title={context?.content?.howDidYouLearnAbout?.header}
        >
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'center',
                    width: '100%',
                    alignItems: 'space-between'
                }}
            >
                <div className="floors">
                    {
                        optionsData.map((option, index) => (
                            <HowDidYouHear
                                key={`HowDidHear-HowDidYouHear-${index}`}
                                title={option.title}
                                value={option.value}
                            />
                        ))
                    }
                </div>
            </div>
        </Section>
    )
}

const HowDidYouHear = ({ title, value }) => {
    const context = useContext(AppContext);
    const howDidHearData = context.selectedData['howDidHear'] || {};

    function moveToNext() {
        const nextStep = 21;
        context.setStep(nextStep);
    }

    function handleItemClick() {
        const _howDidHearData = {...howDidHearData};
        if (!value) { // unselect the selected items for signle selection
            const keys = Object.keys(_howDidHearData);
            for (let i = 0; i < keys.length; i++) {
                _howDidHearData[keys[i]] = false;
            }
        }

        context.setSelectedData('howDidHear', {
            ..._howDidHearData,
            [title]: !value,
        });

        moveToNext();
    }
    
    return (
        <li
            className={`floor ${value && 'floor--selected'}`}
            onClick={handleItemClick}
        >
            <label className="floor-inner">
                <span className="floor__title">{title}</span>
            </label>
        </li>
    )
}

export default HowDidHear