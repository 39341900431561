import React from 'react';
import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import DetailsItem from '../DetailsItem';
import { ReactComponent as CheckBoxImage } from '../../assets/svgs/checkbox.svg'
import { ReactComponent as UnCheckBoxImage } from '../../assets/svgs/uncheckbox.svg'
import './style.css';

const useStyles = makeStyles(theme => ({
  row: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    padding: '18px 20px 0',
  },

  title: {
    margin: '25px 0 4px',
    fontWeight: '600',
    fontFamily: 'Averta-Extrabold, Source Sans Pro, sans-serif',
    flex: 1,
  },

  valueContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },

  value: {
    marginLeft: 8,
    textAlign: 'right',
  },

  bold: {
    fontWeight: '600',
    fontFamily: 'Averta-Bold, Source Sans Pro, sans-serif',
  },

  green: {
    color: '#00c6a6',
  },

  lineThrough: {
    textDecoration: 'line-through',
  },

  checkbox: {
    marginLeft: 18,
  },

  itemStyle: {
    borderBottom: 'none',
  },
}))

const DetailsServiceItem = ({ data, titleStyle, onSelect }) => {
  const {
    title,
    key,
    value,
    valueType,
    value1,
    value1Type,
    hasCheckbox,
    selected,
    data: detailsItemData,
  } = data;
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <div className={classes.row}>
        <Typography className={[classes.title, titleStyle].join(' ')} variant="h5" align="center">
          {title}
        </Typography>

        {(value || value1 || hasCheckbox) && (
          <div className={classes.valueContainer}>
            {value1 && (
              <Typography
                className={`
                  ${classes.bold}
                  ${value1Type === 'line' && classes.lineThrough}
                `}
                variant="subtitle2"
              >
                {value1}
              </Typography>
            )}
            <Typography
              className={`
                ${classes.value}
                ${classes.bold}
                ${valueType === 'green' && classes.green}
                ${valueType === 'line' && classes.lineThrough}
              `}
              variant="subtitle2"
            >
              {value}
            </Typography>

            {hasCheckbox && (
              <div
                className={classes.checkbox}
                onClick={() => {
                  if (onSelect) {
                    onSelect(key, selected);
                  }
                }}
              >
                {selected
                  ? <CheckBoxImage />
                  : <UnCheckBoxImage />
                }
              </div>
            )}
          </div>
        )}
      </div>

      {detailsItemData.map((item, index) => (
        <DetailsItem
          key={`details-item-${index}`}
          data={item}
          itemStyle={index === detailsItemData.length - 1 && classes.itemStyle}
          onSelect={onSelect && onSelect}
        />
      ))}
      {/* <DetailsItem /> */}
    </div>
  );
}

export default DetailsServiceItem;
