import React, { useContext } from 'react';
import { Section } from '../../components/Section/Section';
import { CountPicker } from '../../components/CountPicker/CountPicker';
import AppContext from '../../AppContext';

import './WhichItems.css';

export function WhichItems(props) {
  const context = useContext(AppContext);

  function onChange(name, value) {
    context.setSelectedData('items', {
      ...context.selectedData['items'],
      [name]: value,
    });
  }

  const selected = context.selectedData['items'] || {};

  return (
    <Section
      canContinue={Object.values(selected).length && Object.values(selected).some(v => v)}
      className="WhichItems"
      step={props.step}
      title={context.content?.howManyItems?.header}
    >
      <div
        id='whichItems'
        style={{
          display: 'flex',
          flexFlow: 'row wrap',
          justifyContent: 'center',
        }}
      >
        {context.content?.howManyItems?.cats?.items && context.content.howManyItems.cats.items.map((item, i) => {
          const selected = context.selectedData['items'] || {};
          const value = selected[item.name];
          item.icon = context.content.howManyItems.images[i].fields.file.url;

          return (
            <CountPicker
              key={`WhichItems-CountPicker-${i}`}
              {...item}
              value={value}
              onChange={onChange}
            />
          );
        })}
      </div>
    </Section>
  )
}
