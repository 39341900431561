import React, { useContext, useEffect } from "react";
import { Section } from "../../components/Section/Section";
import AppContext from "../../AppContext";

import "./Floor.css";

const Floor = ({ step, prefix = '' }) => {
  const context = useContext(AppContext);
  const floorData = context.selectedData[`${prefix}Floor`] || {};
  const stepData = (prefix ? context.content?.destinationFloor : context.content?.floor) || {};

  const optionsData = [];
  if (stepData?.cards?.items) {
    for (let i = 0; i < stepData.cards.items.length; i++) {
      const item = stepData.cards.items[i];
      optionsData.push({
        title: item.title,
        value: !!floorData[item.title],
      });
    }
  }

  useEffect(() => {
    context.setCanContinue(step, false);
  }, []);

  return (
    <Section
      canContinue={Object.values(floorData).some(v => !!v)}
      step={step}
      title={stepData.header}
    >
      <ul className="floors">
        {optionsData.map((option, index) => {
          return (
            <FloorChoice
              key={`FloorChoice-${index}`}
              title={option.title}
              value={option.value}
              prefix={prefix}
            />
          );
        })}
      </ul>
    </Section>
  );
};

const FloorChoice = ({ title, value, prefix }) => {
  const context = useContext(AppContext);
  const floorData = context.selectedData[`${prefix}Floor`] || {};

  function handleItemClick() {
    context.setSelectedData(`${prefix}Floor`, {
      ...floorData,
      [title]: !value,
    });
  }

  return (
    <li
      className={`floor ${value && 'floor--selected'}`}
      onClick={handleItemClick}
    >
      <label className="floor-inner">
        <span className="floor__title">{title}</span>
      </label>
    </li>
  );
};

export default Floor;
