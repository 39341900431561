import React from 'react';
import { addDays, format, isBefore, isEqual, isSameDay, startOfWeek, parse, eachMonthOfInterval, eachDayOfInterval, subDays } from 'date-fns';
import axios from 'axios';
import LoadingModal from '../../components/LoadingModal';
import './Calendar.css';

class Calendar extends React.Component {
  state = {
    startDate: startOfWeek(new Date()),
    lastDate: null,
    availableDates: [],
    loadedMonths: {},
  }

  constructor(props) {
    super(props);

    this.state = {
      startDate: subDays(startOfWeek(props.startDate, { weekStartsOn: 0 }) || this.state.startDate, 1),
      lastDate: addDays(props.startDate, 31),
      availableDates: [],
      loadedMonths: {},
      loading: false,
    }
  }

  seeMore = () => {
    this.setState({
      lastDate: addDays(this.state.lastDate, 27),
    }, () => {
      this.loadAvailableDates();
    })
  }

  select = (date) => {
    this.props.onSelect && this.props.onSelect(date);
  }

  loadAvailableDates() {
    if (this.props.context.content.prices.prices.plans.filter(plan => plan.size == this.props.context.selectedData.unit)[0]) {
      const months = eachMonthOfInterval({
        start: this.state.startDate,
        end: this.state.lastDate
      }).filter((month) => !this.state.loadedMonths[month.toString()]);
      const calendarId = this.props.context.content.prices.prices.plans.filter(plan => plan.size == this.props.context.selectedData.unit)[0].volume <= 8 ? 4053466 : 4053439;
      const formattedMonths = months.map(item => format(item, 'yyyy-LL'));

      this.setState({ loading: true });
      axios.get(`/availability/dates?calendarID=${calendarId}`, {
        params: { months: formattedMonths }
      }).then((response) => {
        const _availableDates = [...this.state.availableDates];
        const _loadedMonths = {};
        response.data.forEach(({ date }) => {
          _availableDates.push(parse(date, 'yyyy-LL-dd', new Date()));
        });
        months.forEach(item => {
          _loadedMonths[item.toString()] = true;
        });

        this.setState({
          availableDates: _availableDates,
          loadedMonths: {
            ...this.state.loadedMonths,
            ..._loadedMonths,
          },
          loading: false,
        });
      })
      .catch(() => {
        this.setState({ loading: false });
      });
    }
  }

  componentDidMount() {
    this.loadAvailableDates();
  }

  render() {
    let { context } = this.props;
    const { startDate, lastDate, loading } = this.state;

    const dates = eachDayOfInterval({
      start: startDate,
      end: lastDate,
    });

    const datesFormatted = dates.reduce((acc, date) => {
      return {
        ...acc,
        [date.getFullYear()]: {
          ...acc[date.getFullYear()],
          [date.getMonth()]: [...(
            (
              acc[date.getFullYear()] || []
            )[date.getMonth()] || []
          ), date]
        },
      };
    });

    const days = ['S', 'M', 'T', 'W', 'T', 'F', 'S'];

    return (
      <>
        <div className="Calendar">
          <div className="Calendar__legend">
            <div className="Calendar__legendItem">Available</div>
            <div className="Calendar__legendItem Calendar__legendItem--fill">Unavailable</div>
          </div>
          <div className="Calendar__header">
            {days.map((day) => {
              return (
                <div className="Calendar__headerDay">{day}</div>
              )
            })}
          </div>
          <div>
            {Object.keys(datesFormatted).map((year) => {
              return Object.keys(datesFormatted[year]).map((month) => {

                const firstDay = datesFormatted[year][month][0];
                const startOfWeekOfFirstDay = startOfWeek(firstDay, { weekStartsOn: 0 });
                const lastFillerDay = subDays(firstDay, 1);

                let fillerDays = [];
                if (+lastFillerDay >= +startOfWeekOfFirstDay) {
                  fillerDays = eachDayOfInterval({
                    start: startOfWeekOfFirstDay,
                    end: lastFillerDay,
                  });
                }

                return (
                  <div className="Calendar__month">
                    <span className="Calendar__monthLabel">{format(new Date(1, month).setFullYear(year), 'LLLL yyyy')}</span>
                    <div className="Calendar__days">
                      {fillerDays.map((date) => {
                        return (
                          <div className="Calendar__dayWrap" style={{ visibility: 'hidden' }}>
                            <button
                              className={`Calendar__day`}
                            >
                              {date.getDate()}
                            </button>
                          </div>
                        )
                      })}
                      {datesFormatted[year][month].map((date) => {
                        const isPast = isBefore(date, new Date());
                        const isAvailable = this.state.availableDates.some((availableDate) => isSameDay(date, availableDate));
                        const isSelected = isEqual(date, this.props.selected);
                        return (
                          <div className="Calendar__dayWrap">
                            <button
                              onClick={() => this.select(date)}
                              disabled={!isAvailable}
                              className={`Calendar__day ${isAvailable ? ` Calendar__day--available` : !isPast && ` Calendar__day--unavailable`}${isPast ? ` Calendar__day--past` :
                                ''}${isSelected ? ` Calendar__day--selected` : ''}`}
                            >
                              {date.getDate()}
                            </button>
                          </div>
                        )
                      })}
                    </div>
                  </div>
                )
              })
            })}
          </div>
          <button className="Calendar__more" onClick={() => this.seeMore()}>See more</button>
        </div>
        <LoadingModal
          title={context?.content?.misc?.loaderTitle}
          open={loading}
          backgroundColor="transparent"
          backdropColor="rgba(255, 255, 255, 0.8)"
        />
      </>
    );
  }
}


export default Calendar;
