import React from "react";
import { parseISO } from 'date-fns';
import { Header } from "./components/Header/Header";
import Footer from './components/Footer';
import Intro from "./pages/Intro/Intro";
import UnitSize from "./pages/UnitSize/UnitSize";
import MonthlyStorageChoice from './pages/MonthlyStorageChoice/MonthlyStorageChoice';
import HelpChoice from "./pages/HelpChoice/HelpChoice";
import HelpChoice2 from "./pages/HelpChoice/HelpChoice2";
import AppContext from "./AppContext";
import { throttle } from "./Utils";
// import HelpMoving from "./pages/HelpMoving/HelpMoving";
import CustomizeQuote from "./pages/CustomizeQuote/CustomizeQuote";
import GoodsElevator from "./pages/CustomizeQuote/GoodsElevator";
// import QuoteReady from "./pages/QuoteReady/QuoteReady";
import Protection from "./pages/Protection/Protection";
import PickDate from "./pages/PickDate/PickDate";
import PickDatePeriod from "./pages/PickDate/PickDatePeriod";
import { PickTime } from "./pages/PickTime/PickTime";
import Address from "./pages/Address/Address";
import { scroller } from "react-scroll";
import ContactInfo from "./pages/ContactInfo/ContactInfo";
// import { QuestionPurpose } from "./pages/QuestionPurpose/QuestionPurpose";
// import { QuestionHelpPacking } from "./pages/QuestionHelpPacking/QuestionHelpPacking";
import { WhichRooms } from "./pages/WhichRooms/WhichRooms";
import { WhichItems } from "./pages/WhichItems/WhichItems";
// import { PredictionResult } from "./pages/PredictionResult/PredictionResult";
import { WhatsPacked } from "./pages/WhatsPacked/WhatsPacked";
import WhatMakesDiff from "./pages/WhatMakesDiff";
import Floor from "./pages/Floor";
import Elevator from "./pages/Elevator";
import Building from "./pages/Building";
import ShipmentDestination from "./pages/ShipmentDestination";
import ShipmentDestinationAddress from "./pages/ShipmentDestinationAddress";
// import HowManyItems from "./pages/HowManyItems";
import MovingOutOrStoringExtra from "./pages/MovingOutOrStoringExtra";
// import NeedHelpPacking from "./pages/NeedHelpPacking";
import AllSet from "./pages/AllSet";
// import HowPaying from "./pages/HowPaying";
import HowDidHear from "./pages/HowDidHear";
// import SelectPackage from "./pages/SelectPackage";
// import AddServices from "./pages/AddServices";
import ConfirmDetails from './pages/ConfirmDetails';
import WeWillContact from './pages/WeWillContact';
// import GoodsElevatorChoice from "./pages/GoodsElevatorChoice/GoodsElevatorChoice";

const contentful = require("contentful");

class App extends React.Component {
    stepsCount = 24;
    contentLoaded = false;

    setSelectedData = (key, value) => {
        this.setState({
            selectedData: {
                ...this.state.selectedData,
                [key]: value,
            },
        }, _ => {
            window.selectedData = JSON.stringify(this.state.selectedData)
        })
    };

    setSelectedObject = (obj, reset = false) => {
        const oldData = reset ? {} : this.state.selectedData;
        this.setState({
            selectedData: {
                ...oldData,
                ...obj,
            },
        }, _ => {
            window.selectedData = JSON.stringify(this.state.selectedData)
        })
    };

    setVisibleStep = (step, visible) => {
        this.setState({
            visibleSteps: {
                ...this.state.visibleSteps,
                [step]: !!visible,
            },
        });
    };

    setVisibleSteps = (visibleSteps) => {
        this.setState({ visibleSteps });
    };

    setFocusedStep = (step) => {
        if (this.state.step < step) {
            this.setState({ step });
        }

        this.setState({
            focusedStep: step,
        });
    };

    onScroll = throttle(() => {
        if (this.state.forceFocusedStep !== null) {
            this.setFocusedStep(this.state.forceFocusedStep);
        } else if (this.state.visibleSteps[0]) {
            this.setFocusedStep(0);
        } else if (this.state.visibleSteps[this.state.step]) {
            this.setFocusedStep(this.state.step);
        } else {
            const center = window.innerHeight / 2;

            const steps = new Array(this.stepsCount)
                .fill()
                .map((_, i) => i)
                .reduce((acc, step) => {
                    acc.push(step);
                    if (this.state.substepsCount[step]) {
                        acc.push(
                            ...new Array(this.state.substepsCount[step])
                                .fill()
                                .map((_, i) => `${step}-${i + 1}`)
                        );
                    }

                    return acc;
                }, []);

            const centerStep = steps.reduce(
                (acc, i) => {
                    const id = `step-${i}`;

                    if (this.state.substepsCount[i]) {
                    }

                    const element = document.getElementById(id);
                    if (!element || !this.state.visibleSteps[i]) {
                        return acc;
                    }

                    const distance = Math.abs(
                        element.getBoundingClientRect().top - center
                    );

                    if (!acc.distance || acc.distance > distance) {
                        acc.distance = distance;
                        acc.step = i;
                    }

                    return acc;
                },
                { step: null, distance: null }
            );

            const newFocusedStep =
                centerStep === null ? this.state.step : centerStep.step;
            this.setFocusedStep(newFocusedStep || 0);
        }
    }, 5);

    setForceFocusedStep = (step) => {
        this.setState({
            forceFocusedStep: step,
        });
    };

    onVisibilityChange = (state, eventStep) => {
        const newState = {
            ...this.state.visibleSteps,
            [eventStep]: state,
        };

        if (this.state.step === 0) {
            newState[0] = true;
            newState[-1] = true; // hack to detect first slide was set
        }

        if (Object.keys(newState).length >= 3 && this.state.visibleSteps[-1]) {
            delete newState[0];
            delete newState[-1];
        }

        this.setVisibleSteps(newState);
    };

    setCanContinue = (step, status) => {
        this.setState({
            canContinue: {
                ...this.state.canContinue,
                [step]: !!status,
            },
        });
    };

    scrollToStep = (newStep, center = false) => {
        const stepEl = document.getElementById(`step-${newStep}`);
        scroller.scrollTo(`step-${newStep}`, {
            duration: 400,
            smooth: true,
            offset: (stepEl && center) ? -(stepEl.clientHeight / 2) : 0,
        });

        setTimeout(() => {
            this.forceUpdate();
            this.setForceFocusedStep(newStep);
            this.setForceFocusedStep(null);
            this.onScroll();
        }, 2000);
    };


    setStep = (step) => {
        this.setState({ step }, () => {
            this.scrollToStep(step);
        });
        window.step = step
    };

    setSubstepsCount = (step, count) => {
        this.setState({
            substepsCount: {
                ...this.state.substepsCount,
                [step]: count,
            },
        });
    };

    setBookingID = (bookingID) => {
        this.setState({ bookingID });
        window.bookingID = bookingID;
    };

    state = {
        step: 0,
        stepsCount: this.stepsCount,
        setStep: this.setStep,
        setSubstepsCount: this.setSubstepsCount,
        next: this.next,
        selectedData: {
            help: "",
        },
        substepsCount: {},
        canContinue: {},
        visibleSteps: { "-1": true, 0: true },
        setVisibleStep: this.setVisibleStep,
        setSelectedData: this.setSelectedData,
        setSelectedObject: this.setSelectedObject,
        calcQuote: this.calcQuote,
        focusedStep: null,
        forceFocusedStep: null,
        bookingID: null,
        setFocusedStep: this.setFocusedStep,
        setForceFocusedStep: this.setForceFocusedStep,
        onVisibilityChange: this.onVisibilityChange,
        setCanContinue: this.setCanContinue,
        scrollToStep: this.scrollToStep,
        setBookingID: this.setBookingID,
    };

    componentDidUpdate() {
        const savedContextString = localStorage.getItem('context');
        const savedStep = localStorage.getItem('step');
        if (savedContextString !== 'undefined'
            && savedContextString !== undefined
            && this.state.content
            && Object.keys(this.state.content).length === 32
            && this.state.step === 0
            && !this.contentLoaded
        ) {
            this.contentLoaded = true;
            const savedContext = JSON.parse(JSON.parse(savedContextString));
            if (savedContext) {
                const { date, time } = savedContext;
                this.setState({
                    selectedData: {
                        ...savedContext,
                        successfullyBooked: false,
                        bookingInProcess: false,
                        date: date ? parseISO(date) : null,
                        time: time ? parseISO(time) : null,
                    },
        
                })
                this.setStep(savedStep || 0)
                this.forceUpdate();
                // todo:
                window.selectedData = JSON.stringify(savedContext)
            }

        }
    }

    onUnload(e) {
        localStorage.setItem('step', window.step && parseInt(window.step) !== 24 ? window.step : 0);
        localStorage.setItem('context', JSON.stringify(window.selectedData));
    }

    componentDidMount() {
        window.addEventListener('beforeunload', this.onUnload);

        this.setState({
            focusedStep: this.state.step,
        });

        document.addEventListener("scroll", this.onScroll, { passive: true });
        this.onScroll();
        const contentfulClient = contentful.createClient({
            space: "iim1pelwcgkd",
            accessToken: "bDpVzFxyzA6wjl81z2kqMq_1kSJR7wvLzH00bSbQk18",
        });
        contentfulClient
            .getEntry("6qgBShul4igRI8RNWI2Lnd")
            .then((res) => {
                this.setState({
                    content: {
                        misc: res.fields,
                        ...this.state.content,
                    },
                });
            })
            .catch((err) => console.log(err));
        contentfulClient
            .getEntry("2O1uLu9Sb624ewoIGl9IK4")
            .then((res) => {
                this.setState({
                    content: {
                        whatMakesDiff: res.fields,
                        ...this.state.content,
                    },
                });
            })
            .catch((err) => console.log(err));

        contentfulClient
            .getEntry("7lpWL07d7a1FjNv8807wbf")
            .then((res) => {
                this.setState({
                    content: {
                        buildingType: res.fields,
                        ...this.state.content,
                    },
                });
            })
            .catch((err) => console.log(err));

        contentfulClient
            .getEntry("5rsmm8PQfIMz4sawrHHRRu")
            .then((res) => {
                this.setState({
                    content: {
                        destinationBuildingType: res.fields,
                        ...this.state.content,
                    },
                });
            })
            .catch((err) => console.log(err));

        contentfulClient
            .getEntry("3VOMj2SNlqHY07j3WYtS4X")
            .then((res) => {
                this.setState({
                    content: {
                        floor: res.fields,
                        ...this.state.content,
                    },
                });
            })
            .catch((err) => console.log(err));

        contentfulClient
            .getEntry("7bZGbrVzYT3HSNtH5hIOfW")
            .then((res) => {
                this.setState({
                    content: {
                        destinationFloor: res.fields,
                        ...this.state.content,
                    },
                });
            })
            .catch((err) => console.log(err));

        contentfulClient
            .getEntry("3OP3lQ037JHybZ12JTFoPg")
            .then((res) => {
                this.setState({
                    content: {
                        whatElevatorType: res.fields,
                        ...this.state.content,
                    },
                });
            })
            .catch((err) => console.log(err));

        contentfulClient
            .getEntry("3pqU2Cu8MxkOPLDz9WI6uV")
            .then((res) => {
                this.setState({
                    content: {
                        shipmentDestination: res.fields,
                        ...this.state.content,
                    },
                });
            })
            .catch((err) => console.log(err));

        contentfulClient
            .getEntry("5yQe5DBID8EljSRVkDANnq")
            .then((res) => {
                this.setState({
                    content: {
                        howDidYouLearnAbout: res.fields,
                        ...this.state.content,
                    },
                });
            })
            .catch((err) => console.log(err));
        contentfulClient
            .getEntry("ABbRKZucYnmmw46seP8bA")
            .then((res) => {
                this.setState({
                    content: {
                        youAllSet: res.fields,
                        ...this.state.content,
                    },
                });
            })
            .catch((err) => console.log(err));

        contentfulClient
            .getEntry("7C4zma7Mnych0TAZKmd6p4")
            .then((res) => {
                this.setState({
                    content: {
                        selectUnit: res.fields,
                        ...this.state.content,
                    },
                });
            })
            .catch((err) => console.log(err));
        contentfulClient
            .getEntry("3bqzw3KEfu48EA24nf9af1")
            .then((res) => {
                this.setState({
                    content: {
                        monthlyStorage: res.fields,
                        ...this.state.content,
                    },
                });
            })
            .catch((err) => console.log(err));
        contentfulClient
            .getEntry("1ccdgnv33o8zaIDWNI2z4u")
            .then((res) => {
                this.setState({
                    content: {
                        helpChoice: res.fields,
                        ...this.state.content,
                    },
                });
            })
            .catch((err) => console.log(err));
        contentfulClient
            .getEntry("3qJjlaAYa5lgphuxeNxm9W")
            .then((res) => {
                this.setState({
                    content: {
                        helpChoice2: res.fields,
                        ...this.state.content,
                    },
                });
            })
            .catch((err) => console.log(err));
        // contentfulClient
        //  .getEntry("56AESvIypZYll8mvUfJX0i")
        //  .then((res) => {
        //      this.setState({
        //          content: {
        //              paymentMethod: res.fields,
        //              ...this.state.content,
        //          },
        //      });
        //  })
        //  .catch((err) => console.log(err));

        contentfulClient
            .getEntry("1oo0S1uwdhFC4HZXHoAVfc")
            .then((res) => {
                this.setState({
                    content: {
                        whatBeingPacked: res.fields,
                        ...this.state.content,
                    },
                });
            })
            .catch((err) => console.log(err));
        contentfulClient
            .getEntry("20494EW7v418nmDBz4Ss1A")
            .then((res) => {
                this.setState({
                    content: {
                        whichRooms: res.fields,
                        ...this.state.content,
                    },
                });
            })
            .catch((err) => console.log(err));
        contentfulClient
            .getEntry("IUqhMg26C55rvW0OzhIPe")
            .then((res) => {
                this.setState({
                    content: {
                        howManyItems: res.fields,
                        ...this.state.content,
                    },
                });
            })
            .catch((err) => console.log(err));
        contentfulClient
            .getEntry("6dILWLqwTVa5SkLPdPqLUt")
            .then((res) => {
                this.setState({
                    content: {
                        helpMoving: res.fields,
                        ...this.state.content,
                    },
                });
            })
            .catch((err) => console.log(err));
        contentfulClient
            .getEntry("6ViwpuWcRJmFeUMLOtvf9Y")  // helpMoving
            .then((res) => {
                this.setState({
                    content: {
                        customizeQuote: res.fields,
                        ...this.state.content,
                    },
                });
            })
            .catch((err) => console.log(err));
        // contentfulClient
        //  .getEntry("2XoeZAx8EVJW28ZfPpODVk")
        //  .then((res) => {
        //      this.setState({
        //          content: {
        //              quoteIsReady: res.fields,
        //              ...this.state.content,
        //          },
        //      });
        //  })
        //  .catch((err) => console.log(err));
        contentfulClient
            .getEntry("4a2rQaBEqo0B9u2f3Wpi4X")
            .then((res) => {
                this.setState({
                    content: {
                        package: res.fields,
                        ...this.state.content,
                    },
                });
            })
            .catch((err) => console.log(err));
        
        contentfulClient
            .getEntry("4gxshHS6rtrNBgMaY65vTI")
            .then((res) => {
                this.setState({
                    content: {
                        contactInfo: res.fields,
                        ...this.state.content,
                    },
                });
            })
            .catch((err) => console.log(err));
        contentfulClient
            .getEntry("6jhJJA8AdZsY5TQ0UcgkFs")
            .then((res) => {
                this.setState({
                    content: {
                        protection: res.fields,
                        ...this.state.content,
                    },
                });
            })
            .catch((err) => console.log(err));
        contentfulClient
            .getEntry("686mXoCaW1juWhnMFeZBxV")
            .then((res) => {
                this.setState({
                    content: {
                        confirmAppt: res.fields,
                        ...this.state.content,
                    },
                });
            })
            .catch((err) => console.log(err));
        contentfulClient
            .getEntry("25kU0u7tonJ5QVnL8Nd9wu")
            .then((res) => {
                this.setState({
                    content: {
                        prices: res.fields,
                        ...this.state.content,
                    },
                });
            })
            .catch((err) => console.log(err));
        contentfulClient
            .getEntry("sDptS3K3Q3Nn1q9uTmwZ2")
            .then((res) => {
                this.setState({
                    content: {
                        moveInDate: res.fields,
                        ...this.state.content,
                    },
                });
            })
            .catch((err) => console.log(err));
        contentfulClient
            .getEntry("4J4QfE4XZAR3Il4TJFE1OM")
            .then((res) => {
                this.setState({
                    content: {
                        selectArrivalWindow: res.fields,
                        ...this.state.content,
                    },
                });
            })
            .catch((err) => console.log(err));
        contentfulClient
            .getEntry("1zZvLcG9CYtB8ZN2nQYlAN")
            .then((res) => {
                this.setState({
                    content: {
                        helpPack: res.fields,
                        ...this.state.content,
                    },
                });
            })
            .catch((err) => console.log(err));
        contentfulClient
            .getEntry("68b9QCVLgwomUIeGbufU1y")
            .then((res) => {
                this.setState({
                    content: {
                        intro: res.fields,
                        ...this.state.content,
                    },
                });
            })
            .catch((err) => console.log(err));
        contentfulClient
            .getEntry("4dYtk04TckcqeiesbZqowK")
            .then((res) => {
                this.setState({
                    content: {
                        address: res.fields,
                        ...this.state.content,
                    },
                });
            })
            .catch((err) => console.log(err));
        contentfulClient
            .getEntry("5f24Ypo7KPC63bKscAhngy")
            .then((res) => {
                this.setState({
                    content: {
                        movingOutOr: res.fields,
                        ...this.state.content,
                    },
                });
            })
            .catch((err) => console.log(err));

        contentfulClient
            .getEntry("4YxrMrcbOrNusCY8r7mi4u")
            .then((res) => {
                this.setState({
                    content: {
                        goodsElevator: res.fields,
                        ...this.state.content,
                    },
                });
            })
            .catch((err) => console.log(err));

        contentfulClient
            .getEntry("57c05R83PEK1H4nGn0y7ji")
            .then((res) => {
                this.setState({
                    content: {
                        destinationGoodsElevator: res.fields,
                        ...this.state.content,
                    },
                });
            })
            .catch((err) => console.log(err));

        contentfulClient
            .getEntry("6Ei7529QT6I4chhSxGDTOR")
            .then((res) => {
                this.setState({
                    content: {
                        whereToShip: res.fields,
                        ...this.state.content,
                    },
                });
            })
            .catch((err) => console.log(err));

        contentfulClient
            .getEntry("3ngcO1fGPU73Yz8VPcr3U1")
            .then((res) => {
                this.setState({
                    content: {
                        dateApprox: res.fields,
                        ...this.state.content,
                    },
                });
            })
            .catch((err) => console.log(err));

        contentfulClient
            .getEntry("5LnkX0vX7UXWdv12j6q4Wr")
            .then((res) => {
                this.setState({
                    content: {
                        weWillContact: res.fields,
                        ...this.state.content,
                    },
                });
            })
            .catch((err) => console.log(err));
    }

    componentWillUnmount() {
        document.removeEventListener("scroll", this.onScroll, { passive: true });
        window.removeEventListener('beforeunload', this.onUnload);
    }

    submitContactForm = null;

    handleSubmitContactForm = (e) => {
        if (this.submitContactForm) {
            this.submitContactForm(e);
        }
    }

    bindSubmitContactForm = (submitContactForm) => {
        this.submitContactForm = submitContactForm;
    }

    render() {
        if (!this.state.selectedData) {
            return null;
        }

        // DON'T FORGET TO INCREASE stepsCount VARIABLE IF YOU CHANGED STEPS COUNT
        return (
            <AppContext.Provider value={this.state}>
                <div className="App">
                    <Header />
                    <main className="Main">
                        {this.state.selectedData?.successfullyBooked
                            ? <AllSet step={26} full={true} />
                            : (
                                <>
                                    <Intro step={0} />
                                    <WhatMakesDiff
                                        step={1}
                                        onVisiblityChange={this.onVisibilityChange}
                                    />
                                    <UnitSize step={2} />
                                    <MonthlyStorageChoice step={3} prefix="monthly-storage" />
                                    <HelpChoice step={4} prefix="help-choice" />
                                    {this.state.selectedData.help !== 'DIY' && (
                                        <HelpChoice2 step={5} prefix="help-choice" />
                                    )}
                                    {/* <SelectPackage step={5} /> */}
                                    {/* <AddServices step={18} /> */}
                                    {/* Note: Replaced the SelectPackage and AddServices with CustomizeQuote */}
                                    <CustomizeQuote step={6} />

                                    {this.state.selectedData.help === 'DIY' ? (<></>) : (
                                        <>
                                            <GoodsElevator step={7} />
                                            <Address step={8} />
                                            <Building step={9} />
                                            <Floor step={10} />
                                            <Elevator step={11} />
                                            <ShipmentDestination step={12} />

                                            {this.state.selectedData["shipment-destination"] === 'warehouse' ? (<></>) : (
                                                <>
                                                    <ShipmentDestinationAddress step={13} prefix="shipment-destination" />
                                                    <Building step={14} prefix="shipment-destination" />
                                                    <Floor step={15} prefix="shipment-destination" />

                                                    <MovingOutOrStoringExtra step={16} />
                                                    <Protection step={17} />
                                                </>
                                            )}
                                        </>
                                    )}

                                    <ContactInfo
                                        step={18}
                                        bindSubmitContactForm={this.bindSubmitContactForm}
                                    />
                                    <PickDatePeriod step={19} />
                                    {/* <PickDate step={22} />
                                    <PickTime step={23} /> */}
                                    <HowDidHear step={20} />
                                    <ConfirmDetails step={21} />

                                    {this.state.selectedData.help === 'DIY' ? (<></>) : (
                                        <>
                                            <WeWillContact step={22} />
                                            <WhatsPacked step={23} />
                                            <WhichRooms step={24} />
                                            <WhichItems step={25} />
                                        </>
                                    )}

                                    <Footer
                                        prefix="shipment-destination"
                                        onSubmitContactForm={this.handleSubmitContactForm}
                                    />
                                </>
                            )
                        }
                    </main>

                </div>
            </AppContext.Provider>
        );
    }
}

export default App;
