import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import 'react-tippy/dist/tippy.css';
import App from './App';
import axios from 'axios';

// axios.defaults.baseURL = 'http://localhost:3005'; // todo:  localhost
// axios.defaults.baseURL = 'https://booking.spacehero.gr:3005';  // prod
axios.defaults.baseURL = 'https://booking-signupform.herokuapp.com'; // temporary API.

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);
