import React, { useContext } from 'react';
import { Section } from '../../components/Section/Section';
import { CountPicker } from '../../components/CountPicker/CountPicker';
import AppContext from '../../AppContext';

import './WhichRooms.css';

export function WhichRooms(props) {
  const context = useContext(AppContext);

  function onChange(name, value) {
    context.setSelectedData('rooms', {
      ...context.selectedData['rooms'],
      [name]: value,
    });
  }

  const selected = context.selectedData['rooms'] || {};

  return (
    <Section
      canContinue={Object.values(selected).length && Object.values(selected).some(v => v)}
      className="WhichRooms"
      step={props.step}
      title={context.content?.whichRooms?.header}
    >
      <div
        style={{
          display: 'flex',
          flexFlow: 'row wrap',
          justifyContent: 'center',
        }}
      >
        {context.content?.whichRooms?.rooms?.items && context.content.whichRooms.rooms.items.map((item, i) => {
          const selected = context.selectedData['rooms'] || {};
          const value = selected[item.name];
          item.icon = context.content.whichRooms.images[i].fields.file.url;

          return (
            <CountPicker
              {...item}
              key={`WhichRooms-CountPicker-${i}`}
              value={value}
              onChange={onChange}
            />
          )
        })}
      </div>
    </Section>
  )
}
