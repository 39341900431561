import React from 'react';
import ReactPhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import './Input.css';

export function PhoneInput(props) {
  const {
    label,
    value,
    name,
    placeholder,
    country,
    autocomplete,
    error,
    onChange,
  } = props;

  return (
    <>
      {label && <div className="InputLabel">{label}</div>}
      <div className="Input" >
        <ReactPhoneInput
          inputClass="Input__input"
          country={country}
          label="Κινητό"
          inputProps={{
            name,
            required: true,
          }}
          value={value}
          placeholder={placeholder}
          onChange={onChange}
          autocomplete={autocomplete || ''}
        />
      </div>
      {error && <span className="InputError">{error}</span>}
    </>
  )
}
