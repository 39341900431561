import React, { useState } from 'react';
import { Typography } from '@material-ui/core';
import Modal from '@material-ui/core/Modal';
import { makeStyles } from '@material-ui/core/styles';
import { ReactComponent as UnCheckMarkImage } from '../../assets/svgs/uncheckedmark.svg';
import { ReactComponent as CheckMarkImage } from '../../assets/svgs/checkmark.svg'

function getModalStyle() {
  const top = 50 ;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

const useStyles = makeStyles(theme => ({
  paper: {
    position: 'absolute',
    width: '50%',
    minWidth: 320,
    maxWidth: 500,
    outline: 'none',
    padding: '24px',
    borderRadius: 4,
  },

  close: {
    position: 'absolute',
    top: 20,
    left: 16,
  },
  
  title: {
    fontSize: '2rem',
    fontWeight: 900,
    fontFamily: 'Averta-Extrabold, Source Sans Pro, sans-serif',
    margin: '0 14px 16px',
    lineHeight: 1.2,
  },

  subTitle: {
    fontSize: '1rem',
    marginBottom: 12,
  },

  bold: {
    fontWeight: 900,
    fontFamily: 'Averta-Bold, Source Sans Pro, sans-serif',
  },

  content: {
    background: 'rgb(249, 249, 249)',
    borderRadius: 4,
    padding: 24,
    marginTop: 16,
  },

  item: {
    display: 'flex',
    alignItems: 'baseline',
  },

  itemIcon: {
    marginRight: 9,
  },

  label: {
    fontSize: 13,
  },

  disabled: {
    color: '#babec1',
  },
}));

function UnitModal(props) {
  const {
    open,
    onClose,
  } = props;
  const {
    title,
    description,
    contentTitle,
    contents = [],
  } = open || {};
  
  const classes = useStyles();
  const [modalStyle] = useState(getModalStyle);

  return (
    <Modal
      aria-labelledby="Modal"
      aria-describedby="Modal"
      open={!!open}
      BackdropProps={
        { style: { backgroundColor: 'rgba(0, 0, 0, 0.5)' }}
      }
      onClose={onClose}
    >
      <div
        style={{
          ...modalStyle,
          backgroundColor: 'white',
        }}
        className={classes.paper}
      >
        <div className={classes.close} onClick={onClose}>
          <UnCheckMarkImage width={16} height={16}/>
        </div>
        <Typography className={classes.title} align="center">
          {title}
        </Typography>
        <Typography className={classes.subTitle} align="center">
          {description}
        </Typography>


        <div className={classes.content}>
          <Typography className={`${classes.subTitle} ${classes.bold}`}>
            {contentTitle}
          </Typography>
          {contents?.checkedList?.map((item, index) => (
            <div
              key={`unit-checkedList-${index}`} 
              className={classes.item}
            >
              <div className={classes.itemIcon}>
                <CheckMarkImage />
              </div>
              <Typography className={classes.label}>
                {item}
              </Typography>
            </div>
          ))}

          {contents?.uncheckedList?.map((item, index) => (
            <div
              key={`unit-uncheckedList-${index}`} 
              className={classes.item}
            >
              <div className={classes.itemIcon}>
                <UnCheckMarkImage />
              </div>
              <Typography className={classes.label}>
                {item}
              </Typography>
            </div>
          ))}

          {contents?.note?.map((item, index) => (
            <Typography
              key={`unit-note-${index}`}
              className={`${classes.label} ${classes.disabled}`}
            >
              {item}
            </Typography>
          ))}
        </div>
      </div>
    </Modal>
  );
}

export default UnitModal;
